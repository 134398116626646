// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';  
// Material-UI Components
import { TextField as MuiTextField } from '@mui/material'; 
// Styles 
import '../../assets/styles/components/customTextField.css'; // Asegúrate de importar tu archivo CSS  

const CustomTextField = ({ InputProps = {}, InputLabelProps = {}, ...props }) => {  
    return (
        <MuiTextField
            style={{ width: '85%' }}
            margin = "normal"
            InputProps={{  
                ...InputProps, // Permite pasar props adicionales si es necesario  
            }}  
            InputLabelProps={{  
                style: { color: '#656CF6', fontFamily: 'silkaregular' },
                ...InputLabelProps, // Permite pasar props adicionales si es necesario  
            }}  
            {...props} // Pasar las otras props (label, value, etc.)  
        />
    );
};

export default CustomTextField;