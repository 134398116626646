// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React from 'react';

// External Libraries
import { Toolbar, Box, AppBar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

// Internal Components
import RoundedTypography from '../../../UI/RoundedTypography';
import AccountMenuButton from './AccountMenuButton';
import NavigationButtons from './NavigationButtons';

// Contexts
import { useAuth } from '../../../../contexts/AuthContext';



const Header = () => {
    const kc = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const isProjectsActive = location.pathname.toLowerCase() === '/home';
    const isCriteriaActive = location.pathname.toLowerCase() === '/criteria';
    const isFeedbackActive = location.pathname.toLowerCase() === '/feedback';
    const regions = ['kanto', 'johto', 'hoenn', 'sinnoh', 'hisui', 'teselia', 'unova', 'kalos', 'alola', 'galar', 'paldea']
    const projectName = location.pathname.split('/')[2];

    return (
        <React.Fragment>
            <div style={{ backgroundColor: '#656aff', height: '5px', width: '100%' }} />
            <AppBar position="static" color="black" sx={{ height: '100px' }}>
                <Toolbar sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    {/* Webpage Title */}
                    <img
                        onClick={() => navigate('')}
                        src={projectName && regions.includes(projectName.toLowerCase()) ? "/Logo-2.png" : "/Logotipo-CELSOS-21.png"}
                        alt="My Logo"
                        style={{ height: 54, cursor: "pointer"}}
                    />
                    <RoundedTypography padding={-1} borderRadius="10px">
                        CellGuard AI
                    </RoundedTypography>

                    {/* Space to push items to the right */}
                    <Box sx={{ flexGrow: 0.05 }} />

                    <NavigationButtons isProjectsActive={isProjectsActive} isCriteriaActive={isCriteriaActive} isFeedbackActive={isFeedbackActive} />
                    <AccountMenuButton kc={kc} />
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default Header;
