// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';

// Material-UI Components
import { Typography } from '@mui/material';


const AccountSettings = () => {
  return(
    <Typography color={'white'}> Nothing here but us chickens! {'(:'}</Typography>
  );
};

export default AccountSettings;
