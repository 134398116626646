// Copyright (C) CELSOS. All rights reserved.

// Core Libraries
import React, { createContext, useState, useContext } from 'react';

// External Libraries
import Keycloak from 'keycloak-js';
import config from '../Config';

// Services
import HttpService from '../services/HTTPService';

const { KEYCLOAK_URL, REALM, CLIENT_ID } = config;

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isKeycloakInitialized, setIsKeycloakInitialized] = useState(false); // Estado adicional para indicar si ya se inicializó

  const initializeKeycloak = async () => {
    const initOptions = {
      url: KEYCLOAK_URL,
      realm: REALM,
      clientId: CLIENT_ID,
      onLoad: 'check-sso',
      checkLoginIframe: true,
      pkceMethod: 'S256',
    };

    const kc = new Keycloak(initOptions);

    try {
      const authenticated = await kc.init({ onLoad: 'check-sso' });
      setKeycloak(kc);
      setIsAuthenticated(authenticated);

      if (authenticated) {
        HttpService.configure(`Bearer ${kc.token}`);
      }

      // Configurar manejo de expiración del token
      kc.onTokenExpired = () => {
        kc.updateToken(30)
          .then((refreshed) => {
            if (refreshed) {
              HttpService.configure(`Bearer ${kc.token}`);
            } else {
              console.warn('Token not refreshed, user needs to log in');
            }
          })
          .catch(() => {
            console.error('Failed to refresh token');
          });
      };

      setIsKeycloakInitialized(true); // Marcar como inicializado
    } catch (error) {
      console.error('Keycloak authentication failed', error);
      setIsKeycloakInitialized(true); // Incluso si falla, marcar como inicializado
    }
  };

  const login = () => keycloak && keycloak.login();
  const logout = () => keycloak && keycloak.logout();
  const getToken = () => keycloak && keycloak.token;

  return (
    <AuthContext.Provider
      value={{
        keycloak,
        isAuthenticated,
        isKeycloakInitialized,
        initializeKeycloak, // Exponer la función para usarla externamente
        login,
        logout,
        getToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
