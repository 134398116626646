// Copyright (C) CELSOS. All rights reserved.

const config = {
    BACKEND_URL: 'https://app.celsospv.com',
    
    //KEYCLOAK CONFIG
    KEYCLOAK_URL: 'https://auth.celsospv.com/',
    REALM:'Celsos',
    CLIENT_ID:'webapp-public-client',
    // Puedes agregar más configuraciones aquí si es necesario
  };
  
  export default config;