// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';

// Styles
import '../../assets/styles/pages/create.css';
import CustomTextField from '../UI/CustomTextField';
import InfoTooltip from './InfoTooltip';

const DimensionsTextBox = ({ label, setSmall, setBig, rows, columns, smallLabel, bigLabel, unit }) => {

    const formatValue = (value) => {
        return value || ''; // Solo devolver el número como string
    };

    const parseValue = (value) => value.replace(/\D/g, ""); // Remover caracteres no numéricos

    const handleRowsChange = (e) => setSmall(parseValue(e.target.value));
    const handleColumnsChange = (e) => setBig(parseValue(e.target.value));

    const handleBlur = () => {
        let updatedRows = parseInt(rows, 10) || '';
        let updatedColumns = parseInt(columns, 10) || '';

        if (updatedRows !== '' && updatedColumns !== '' && updatedRows > updatedColumns) {
            [updatedRows, updatedColumns] = [updatedColumns, updatedRows];
        }

        setSmall(updatedRows);
        setBig(updatedColumns);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            
                <CustomTextField 
                    label={smallLabel}
                    style={{ width: '25%' }} 
                    required 
                    value={formatValue(rows)} // Solo el número
                    onChange={handleRowsChange} 
                    onBlur={handleBlur} 
                    inputProps={{ maxLength: 3 }} 
                />
                {/* Mostrar la unidad en un span fuera del campo de texto */}
                {unit && (
                    <span style={{ fontSize: '1rem', color: 'gray', marginLeft: '5px', marginRight: '5px' }}>
                        X
                    </span>
                )}
                <CustomTextField 
                    label={bigLabel}
                    style={{ width: '25%'}} 
                    required 
                    value={formatValue(columns)} // Solo el número
                    onChange={handleColumnsChange} 
                    onBlur={handleBlur} 
                    inputProps={{ maxLength: 3 }} 
                />
                {/* Mostrar la unidad en un span fuera del campo de texto */}
                {unit && (
                    <span style={{ fontSize: '1rem', color: 'gray', marginLeft: '5px' }}>
                        {unit}
                    </span>
                )}
            <InfoTooltip label={label}/>
        </div>
    );
};




export default DimensionsTextBox;