// Copyright (C) CELSOS. All rights reserved.
// External Libraries
import React, { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

// Internal Project Components
import Theme from './components/UI/Theme';
import Home from './pages/Home';
import MainPage from './pages/MainPage';
import Register from './pages/Register';
import ProjectView from './pages/ProjectView';
import Criteria from './pages/Criteria';
import Feedback from './pages/Feedback';
import NewCriteria from './pages/NewCriteria';
import NotFound from './pages/NotFound';
import AccountSettings from './pages/AccountSettings';
import Profile from './pages/Profile';
import Header from './components/Layout/Global/User/Header';
import SessionlessHeader from './components/Layout/Global/Sessionless/SessionlessHeader';
import LoadingScreen from './components/Layout/Global/LoadingScreen'; // A simple loading component

// Feature Components and Routes
import PrivateRoute from './components/Feature/PrivateRoute';
import PublicRoute from './components/Feature/PublicRoute';

// Contexts
import { useAuth } from './contexts/AuthContext';


const App = () => {
    const { isAuthenticated, initializeKeycloak } = useAuth(); // Fetch `initializeKeycloak` for initialization
    const [isLoading, setIsLoading] = useState(true); // Loading state to wait for `isAuthenticated` initialization

    // Fetch authentication status
    useEffect(() => {
        const init = async () => {
            await initializeKeycloak(); // Initialize Keycloak or fetch auth state
            setIsLoading(false); // Mark loading as complete once authentication state is known
        };

        init();
    }, []);

    // Show loading screen while determining auth state
    if (isLoading) {
        return (
            <ThemeProvider theme={Theme}>
                <div className="Header">
                    {isAuthenticated ? <Header /> : <SessionlessHeader />}
                </div>
                <LoadingScreen />; // Render a loading screen while `isAuthenticated` is being resolved
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={Theme}>
            <div className="Header">
                {isAuthenticated ? <Header /> : <SessionlessHeader />}
            </div>
            <Routes>
                {/* PUBLIC ROUTES */}
                <Route 
                    path="/" 
                    element={
                        <PublicRoute>
                            <MainPage />
                        </PublicRoute>
                    } 
                />
                <Route 
                    path="/register" 
                    element={
                        <PublicRoute>
                            <Register />
                        </PublicRoute>
                    } 
                />

                {/* PRIVATE ROUTES */}
                <Route
                    path="/Home"
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/projects/:projectName"
                    element={
                        <PrivateRoute>
                            <ProjectView />
                        </PrivateRoute>
                    }
                />
                <Route 
                    path="/Criteria" 
                    element={
                        <PrivateRoute>
                            <Criteria />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path="/Feedback" 
                    element={
                        <PrivateRoute>
                            <Feedback />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path="/NewCriteria" 
                    element={
                        <PrivateRoute>
                            <NewCriteria />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path="/Profile" 
                    element={
                        <PrivateRoute>
                            <Profile />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path="/AccountSettings" 
                    element={
                        <PrivateRoute>
                            <AccountSettings />
                        </PrivateRoute>
                    } 
                />
                <Route path="*" element={<NotFound />} />
            </Routes>
            
        </ThemeProvider>
    );
};

export default App;
