// Copyright (C) CELSOS. All rights reserved.
import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#656aff', // Color principal
    },
    secondary: {
      main: '#14013d', // Color secundario
    },
    black:{
        main: '#000007'
    },
    yt:{
        main: '#ffffff'
    }
  },
  
  typography: {  
    fontFamily: '"silkaregular"', // Cambia esto por el nombre de tu fuente  
  },
});

export default Theme;