    // Copyright (C) CELSOS. All rights reserved.
    import React, { useState, useEffect, useRef } from 'react';
    import { useNavigate, useLocation } from 'react-router-dom';
    import '../assets/styles/pages/images.css';
    import { useProject } from '../contexts/ProjectContext';
    import { useModule } from '../contexts/ModuleContext';
    import { useCriteria } from '../contexts/CriteriaContext';
    import BreadcrumbHeader from '../components/Layout/Global/BreadcrumbHeader';
    import ImageSidebarLeft from '../components/Layout/Visualizer/ImageSidebarLeft';
    import ImageSidebarRight from '../components/Layout/Visualizer/ImageSidebarRight';
    import ImageDisplay from '../components/Layout/Visualizer/ImageDisplay';
    import LoadingScreen from '../components/Layout/Global/LoadingScreen';
    import DeleteModal from '../components/Feature/DeleteModal';
    import { Box, Button } from '@mui/material';
    import CriteriaSelect from '../components/Layout/Visualizer/CriteriaSelect';
    import { getDefectStyles } from '../utils/DefectStyles';

    const Visualizer = (projectName) => {
    
    const { project, fetchProject, changeCriteria, error } = useProject();
    const { fetchModule, fetchAllModules, fetchModuleThumbnail, analyzeSingleModule, deleteSetModules } = useModule();
    const [rows, setRows] = useState([]);
    const [projectId, setProjectId] = useState();
    const { fetchCriteria } = useCriteria();
    const [currentImage, setCurrentImage] = useState(null);
    const [defectData, setDefectData] = useState([])
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [moduleId, setModuleId] = useState(); 
    const [criteria, setCriteria] = useState('');
    const [criteriaNames, setCriteriaNames] = useState(['No criteria'])
    const [criteriaIds, setCriteriaIds] = useState([''])
    const img = queryParams.get('img');
    const [visibleDefects, setVisibleDefects] = useState({});
    const [hoveredDefectId, setHoveredDefectId] = useState(null);
    const [isAppReady, setIsAppReady] = useState(false); // Nuevo estado para controlar la carga inicial
    const [open, setOpen] = useState(false);
    const [lens, setLens] = useState('');
    const [uniqueDefects, setUniqueDefects] = useState([])
    
    useEffect(() => {
        
        const projectExists = async () => {
            try {
                const id = await fetchProject(projectName.projectName);
                setProjectId(id);
                const data = await fetchAllModules(id);
                await getModuleList(data);
                setIsAppReady(true)
                return id;
            } catch (error) {
                console.error('Error fetching projects:', error);
                navigate('/404');
            }
        };

        const getCriteria = async () => {
            const criteriaList = await fetchCriteria();
            const ids = criteriaList.map(item => item[0]);
            const names = criteriaList.map(item => item[1]);
            setCriteriaIds(ids);
            setCriteriaNames(names);
            if (project && project[0]) {
                setCriteria(project[0].criteria); // Inicializar el valor del select con el criterio del proyecto
            }
        };
        projectExists();
        getCriteria();
    }, []);

    useEffect(() => {
        const initialVisibility = {};
        defectData.forEach(defect => {
            initialVisibility[defect.id] = true; // Initialize all defects as visible
        });
        setVisibleDefects(initialVisibility);

        const uniqueNames = Array.from(
            new Set(defectData.map((defect) => defect.name))
        );
            const resultObject = uniqueNames.reduce((acc, name, index) => {
            acc[index] = name;
            return acc;
        }, {});
      
          // Guardar los nombres únicos en el estado
        setUniqueDefects(resultObject);
    }, [defectData]);

    useEffect(() => {
        if (error === 404) {
        navigate('/');
        }
    }, [error]);
    
    const handleChange = (e) => {
        setCriteria(e.target.value)
        changeCriteria(e.target.value, projectId)
        window.location.reload();
    }

    const toggleDefectVisibility = (defectId) => {
        setVisibleDefects(prevState => ({
            ...prevState,
            [defectId]: !prevState[defectId]
        }));
    };
        

    const handleRowClick = (params) => {
        setModuleId(params.row.module_id);
        const rowId = params.row.id;
        navigate(`.?img=${rowId}`);
    };

    const getResultIcon = (item) => (item ? "✔️" : item === false ? "❌" : "➖");

    const getModuleList = async (data) => {
        const new_rows = await Promise.all(
            data.map(async (item, index) => {
                const data = await fetchModuleThumbnail(projectName.projectName, item.module_id);
                return {
                module_id: item.module_id,
                id: index + 1,
                accepted: getResultIcon(item.accepted),
                image: data.url,
                };
            })
        );
        setRows(new_rows);

        // Inicializa `moduleId` según el valor de `img` o la primera fila
        const initialModuleId = new_rows[img ? img - 1 : 0]?.module_id;
        if (initialModuleId) {
            setModuleId(initialModuleId);
        }
    };

    // Actualiza la imagen cuando cambia `moduleId`
    useEffect(() => {
        if (!moduleId) return;
        let imgUrl;

        const getImgPath = async () => {
            const data = await fetchModule(projectName.projectName, moduleId);
            setCurrentImage(data.url);
            setDefectData(data.defectData.defects);
            imgUrl = data.url;
        };

        getImgPath();

        return () => {
            if (imgUrl) {
                URL.revokeObjectURL(imgUrl);
            }
        };
    }, [moduleId]);

    const analyzeImage = async () =>{
        try {
            const response = await analyzeSingleModule(projectId, moduleId);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    }

    const handleOpen = () => {
        setOpen(true);        
    }
    
    const handleCloseReload = () => {
        setOpen(false)
        window.location.reload()
    };

    const handleClose = () => {
        setOpen(false)
    }

    const deleteImages = (selectedImage) => {
        deleteSetModules(projectId, selectedImage)
        handleCloseReload()
    }

    return (
        <>
            {isAppReady ?
                <div className="image-container">
                    <BreadcrumbHeader projectName={projectName.projectName} img={img} startAnalysis={analyzeImage} deleteFiles={() => handleOpen('delete')}/>
                    
                    <div className='main-content'>
                        <ImageSidebarLeft rows={rows} onRowClick={handleRowClick} />
                        <div className="image-viewer">
                            <ImageDisplay currentImage={currentImage} defectData={defectData} hoveredDefectId={hoveredDefectId} visibleDefects={visibleDefects} onDefectStyles={getDefectStyles} lens={lens}/>
                            <CriteriaSelect criteria={criteria} onChange={handleChange} criteriaIds={criteriaIds} criteriaNames={criteriaNames}/>
                        </div>
                        <div className="image-sidebar right">
                        <ImageSidebarRight
                            defectData={defectData}
                            onToggleDefectVisibility={toggleDefectVisibility}
                            visibleDefects={visibleDefects}
                            setHoveredDefectId={setHoveredDefectId} // Pass function for hover
                            onDefectStyles={getDefectStyles}
                            uniqueDefects={uniqueDefects}
                            setVisibleDefects={setVisibleDefects}
                            lens={lens}
                            
                        />
                        <div className='bottom-section' style={{ display:'flex', flexDirection:'column'}}>
                            Lens
                            <Box mt={-3} mb={3}>
                                <Button variant='contained' sx={{height:'40px', marginTop:'40px', background: lens === '' ? 'primary' : '#000000'}} onClick={() => setLens('')}>Default</Button>
                                <Button variant='contained' sx={{height:'40px', marginTop:'40px', background: lens === 'severity' ? 'primary' : '#000000'}} onClick={() => setLens('severity')}>Severity</Button>
                                <Button variant='contained' sx={{height:'40px', marginTop:'40px', background: lens === 'rejected' ? 'primary' : '#000000'}} onClick={() => setLens('rejected')}>Rejected</Button>
                            </Box>
                        </div></div>
                    </div>
                    <DeleteModal open={open} handleClose={handleClose} deleteMethod={deleteImages} deleteData={[moduleId]} />
                </div>
                :
                <LoadingScreen />
            }
        </>
    );
};

    export default Visualizer;
