import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Modal, Typography } from '@mui/material';
import '../../assets/styles/components/dragNDrop.css';

const InferenceModal = ({ 
    open, 
    handleClose, 
    selectedRows = [], 
    analyzedRows = [], 
    analysisMethod, 
    allRows = [] 
}) => {
    const [showWarning, setShowWarning] = useState(false);
    const [repeat, setRepeat] = useState(false);
    const [newRows, setNewRows] = useState([]);
    const [newSelectedRows, setNewSelectedRows] = useState([]);

    useEffect(() => {
        let safeSelectedRows = Array.isArray(selectedRows) ? selectedRows : [];

        if (safeSelectedRows.length === 0) {
            safeSelectedRows = allRows || [];
        }

        if (analyzedRows.length !== 0) {
            let res = safeSelectedRows.filter(elemento => !analyzedRows.includes(elemento));

            if (res.length === 0) {
                res = [0];
                setShowWarning(true);
            } else {
                const set = new Set(analyzedRows);
                setShowWarning(safeSelectedRows.some(item => set.has(item)));
            }

            if (JSON.stringify(res) !== JSON.stringify(newRows)) {
                setNewRows(res);
            }
        } else {
            setShowWarning(false);
            if (JSON.stringify(safeSelectedRows) !== JSON.stringify(newRows)) {
                setNewRows(safeSelectedRows);
            }
        }
    }, [selectedRows, analyzedRows, allRows]);

    useEffect(() => {
        if (JSON.stringify(newSelectedRows) !== JSON.stringify(newRows)) {
            setNewSelectedRows(newRows);
        }
    }, [newRows]);

    useEffect(() => {
        if (repeat) {
            setNewSelectedRows(selectedRows);
        } else {
            setNewSelectedRows(newRows);
        }
        setRepeat(false);
    }, [open]);

    const handleChange = () => {
        setNewSelectedRows(repeat ? newRows : selectedRows);
        setRepeat(!repeat);
    };

    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose();
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="small-modal-box">
                <Typography color="primary" variant="h5" sx={{ textAlign: 'left' }}>
                    Search defects?
                </Typography>

                {showWarning ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ textAlign: 'left' }}>
                            This may take some time
                        </Typography>
                        <Typography color="error" variant="h7" sx={{ textAlign: 'left' }}>
                            Some of the selected images have already been analyzed and will be ignored during inference
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox name="repeat" checked={repeat} onChange={handleChange} />
                            }
                            label="Repeat inference"
                        />
                    </div>
                ) : (
                    <Typography variant="h7" sx={{ textAlign: 'left' }}>
                        This may take some time
                    </Typography>
                )}

                <div className="center">
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: '20px' }}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => analysisMethod(newSelectedRows)}
                        sx={{ marginTop: '20px', ml: '20px' }}
                    >
                        Start
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default InferenceModal;
