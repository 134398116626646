// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { createContext, useContext, useState } from 'react';

//Components
import CriteriaService from '../services/CriteriaService';

const CriteriaContext = createContext();

export const useCriteria = () => {
  return useContext(CriteriaContext);
};

export const CriteriaProvider = ({ children }) => {
  const [criteria, setCriteria] = useState(null);
  const [error, setError] = useState(null);

  const fetchCriteria = async () =>{
    try{
      const response = await CriteriaService.getCriteria();
      return response;
    } catch (err) {
      if (err.response && err.response.status == 404){
        throw err;
      } else {
        setError('Error fetching criteria')
      }
    }
  }

  return (
    <CriteriaContext.Provider value={{ criteria, fetchCriteria, error }}>
      {children}
    </CriteriaContext.Provider>
  );
};