// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Copyright (C) CELSOS. All rights reserved. */
.text-field {  
    width: 40%;  
    margin-right: 5%;  
    margin-left: 5%;
}  

.custom-input {  
    color: #656CF6;  
    background: #1b1b49;  
    font-family: 'silkaregular';  
}  

.custom-label {  
    color: #656CF6;  
    font-family: 'silkaregular';  
} `, "",{"version":3,"sources":["webpack://./src/assets/styles/components/customTextField.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;IACI,UAAU;IACV,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,cAAc;IACd,2BAA2B;AAC/B","sourcesContent":["/* Copyright (C) CELSOS. All rights reserved. */\n.text-field {  \n    width: 40%;  \n    margin-right: 5%;  \n    margin-left: 5%;\n}  \n\n.custom-input {  \n    color: #656CF6;  \n    background: #1b1b49;  \n    font-family: 'silkaregular';  \n}  \n\n.custom-label {  \n    color: #656CF6;  \n    font-family: 'silkaregular';  \n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
