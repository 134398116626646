// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React, { useEffect, useState } from 'react';

// Material-UI Components
import { Box, Button, Modal, Typography } from '@mui/material';

//Components
import { useProject } from '../../../contexts/ProjectContext';
import DragNDrop from '../../Feature/DragNDrop';

//Styles
import '../../../assets/styles/components/dragNDrop.css';

const ImportModal = ({ open, handleClose, projectId }) => {
    const { uploadFiles } = useProject();
    const [uploaded, setUploaded] = useState(false);

    useEffect(() => {
            setUploaded(false);
        }, [open]);
    

    const setFiles = async (selectedFiles, onProgress) => {
        setUploaded(false);
        const done = await uploadFiles(selectedFiles, projectId, onProgress);
        if (done) {
            setUploaded(true);
        }
    };

    return (
        <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box className="modal-box">
                <Typography color="primary" variant="h5" sx={{ textAlign: 'left' }}>
                    Import Data
                </Typography>
                <DragNDrop setFiles={setFiles} />
                <div className="center">
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: '20px' }} disabled={!uploaded}>
                        Done
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default ImportModal;
