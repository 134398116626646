// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React from "react";
// Material-UI Components
import { Typography } from "@mui/material";


const LoadingScreen = () => {
  return (
        <div style={{
            position: "fixed",
            left: 0,
            top: "105px",
            width: "100%",
            height: "100%",
            background: "linear-gradient(to bottom,  #000000, #656CF6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: 'column'
        }}>
            <div style={{
                background:'#1e1e1e',
                width: "310px",
                height: "210px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 'solid 2px #656cf6',
                borderRadius: '10px',
                flexDirection: 'column',
            }}>
                <img
                    src="/biggif.gif" // Ruta al archivo del GIF
                    alt="Loading..."
                    className="loading-gif"
                />
                <Typography variant="h6" mt={1}>Loading...</Typography>
            </div>
        </div>
  );
};

export default LoadingScreen;
