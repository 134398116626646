// Copyright (C) CELSOS. All rights reserved.
import React from 'react';

// Material-UI Components
import { Box, Button, ButtonGroup } from '@mui/material';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import { TableView } from '@mui/icons-material';

const ChartSwitchButtons = ({ currentChart, toggleChart }) => {
    return (
        <Box sx={{ justifyContent: 'flex-end', mt: 2 }}>
            <ButtonGroup variant="contained">
                <Button
                    onClick={() => toggleChart('bar')}
                    disabled={currentChart === 'bar'}
                    sx={{
                        backgroundColor: currentChart === 'bar' ? '#656cf6' : '#292767',
                        ':disabled': {
                        backgroundColor: '#656cf6',
                        color: '#fff',
                        },
                    }}
                    >
                        
                    <BarChartIcon />
                </Button>
                    
                <Button
                    onClick={() => toggleChart('pie')}
                    disabled={currentChart === 'pie'}
                    sx={{
                        backgroundColor: currentChart === 'pie' ? '#656cf6' : '#292767',
                        ':disabled': {
                        backgroundColor: '#656cf6',
                        color: '#fff',
                        },
                    }}
                    >
                    <PieChartIcon />
                </Button>
                    
                <Button
                    onClick={() => toggleChart('grid')}
                    disabled={currentChart === 'grid'}
                    sx={{
                        backgroundColor: currentChart === 'grid' ? '#656cf6' : '#292767',
                        ':disabled': {
                        backgroundColor: '#656cf6',
                        color: '#fff',
                        },
                    }}
                    >
                        <TableView />
                </Button>
            </ButtonGroup>
        </Box>
    );
};

export default ChartSwitchButtons;