// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material-UI Components
import {Box, Typography } from '@mui/material';

import RandomTextEffect from '../components/UI/TextFadeIn';

// Styles
import '../assets/styles/pages/home.css';
import Footer from '../components/Layout/Global/Footer';


const Feedback = () => {
  const navigate = useNavigate();

    return (
          <div className="page-container">
            <div className="content text-center">
            <Box height={180}></Box>
                <Box  justifyContent="flex-end" display="flex" flexDirection="column" alignItems="center" backgroundColor="#1a1949BB" borderRadius={2} p={2} mt={2}>
                    <Typography variant="h3" gutterBottom>
                        Add a feedback form here or smth
                    </Typography>
                        <RandomTextEffect fntSize = "180%" skipFurther={true} text="Feedback will be sent to my email?" >
                            
                        </RandomTextEffect>
                        <RandomTextEffect fntSize = "180%" skipFurther={true} text='or will we also have a feedback email?'>
                            
                        </RandomTextEffect>
                </Box>
            </div>
            <Footer />
          </div>
      );
}

export default Feedback;
