// Copyright (C) CELSOS. All rights reserved.
// External Libraries
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// Material-UI Components
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, Typography, DialogTitle } from '@mui/material';


const SessionForm = ({ open, handleClose }) => {
  const [error, setError] = React.useState(null); // Estado para manejar errores de login
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    console.log('wip')
  };

  return (

    //////////////////////
    // LOG out DIALOGUE //
    //////////////////////
    <>
    {Cookies.get('session_token') ? <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: '#000000', // Fondo negro
          color: '#ffffff', // Texto blanco
        }
      }}
    >
      <DialogTitle sx={{ color: '#ffffff', fontWeight: 'bold' }}>ARE YOU SURE YOU WANT TO LOG OUT?</DialogTitle>
        {error && (
          <Typography sx={{ color: 'red', marginBottom: '10px' }}>{error}</Typography>
        )}
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: '#ffffff' }}>
          NOT YET
        </Button>
        <Button onClick={() => console.log('wip')} sx={{ backgroundColor: '#1a1949', color: '#656aff' }}>
          LOG OUT
        </Button>
      </DialogActions>
    </Dialog>
    
     :

    // ///////////////////
    // LOG IN DIALOGUE //
    // ///////////////////
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: '#000000', // Fondo negro
          color: '#ffffff', // Texto blanco
        },
        component: 'form',
        onSubmit: handleSubmit, // Manejamos el envío del formulario
      }}
    >
      <DialogTitle sx={{ color: '#ffffff', fontWeight: 'bold' }}>LOG IN TO YOUR ACCOUNT</DialogTitle>
      <DialogContent>
        {error && (
          <Typography sx={{ color: 'red', marginBottom: '10px' }}>{error}</Typography>
        )}
        <TextField
          autoFocus
          required
          margin="normal"
          id="email"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="filled"
          sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily: 'silkaregular' } }}
          InputLabelProps={{ style: { color: '#656CF6', fontFamily: 'silkaregular' } }} // Color de la etiqueta del campo
          InputProps={{
            style: { color: '#656CF6', fontFamily: 'silkaregular' }, // Color del texto en el input
          }}
        />
        <TextField
          required
          margin="dense"
          id="pw"
          name="password"
          label="Password"
          type="password"
          fullWidth
          variant="filled"
          sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily: 'silkaregular' } }}
          InputLabelProps={{ style: { color: '#656CF6', fontFamily: 'silkaregular' } }} // Color de la etiqueta del campo
          InputProps={{
            style: { color: '#656CF6', fontFamily: 'silkaregular' }, // Color del texto en el input
          }}
        />
        <DialogContentText sx={{ marginTop: 1 }} component="div">
          <a
            href="http://localhost:3000/"
            rel="noreferrer"
            className="darken-hover"
            style={{ textDecoration: 'none' }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: '70%',
                fontFamily: 'silkaregular',
                textAlign: 'bottom',
                color: '#656aff',
              }}
            >
               Forgot your password?
            </Typography>
          </a>
          
        </DialogContentText>
        
        <a
            href="http://localhost:3000/register"
            rel="noreferrer"
            className="darken-hover"
            style={{ textDecoration: 'none', marginLeft: '20px' }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: '70%',
                fontFamily: 'silkaregular',
                textAlign: 'bottom',
                color: '#656aff',
              }}
            >
              Don't have an account? Register here.
            </Typography>
          </a>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: '#ffffff' }}>
          Cancel
        </Button>
        <Button type="submit" sx={{ backgroundColor: '#1a1949', color: '#656aff' }}>
          Log in
        </Button>
      </DialogActions>
    </Dialog>}
    </>
  );
};

export default SessionForm;
