// Copyright (C) CELSOS. All rights reserved.
import React, { useState } from 'react';

// Material-UI Components
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, styled, Typography } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const InputFileUpload = ({ setFiles }) => {
  const [dragOver, setDragOver] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Solo toma el primer archivo
    if (file) {
      setFiles([file]); // Envía un array con un solo archivo
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0]; // Solo toma el primer archivo
    if (file) {
      setFiles([file]); // Envía un array con un solo archivo
    }
    setDragOver(false);
  };

  return (
    <div>
      <div
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          border: dragOver ? '2px dashed #000' : '2px dashed #ccc',
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center',
          marginTop: '35%',
          margin: 'auto',
        }}
      >
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          sx={{ marginBottom: '10px' }}
        >
          <Typography color="white">Upload Image</Typography>
          <VisuallyHiddenInput
            type="file"
            onChange={handleFileChange}
            accept="image/png, image/jpeg, image/jpg"
          />
        </Button>
        <Typography color="white">or drag and drop it here</Typography>
      </div>
    </div>
  );
};

export default InputFileUpload;
