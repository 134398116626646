// Copyright (C) CELSOS. All rights reserved.
// Material-UI Components
import { Box } from '@mui/material';
import { PieChart } from '@mui/x-charts';


const NormalPieChart = ({ data, height, width, outerRadius, colors, ml}) => {
  return (
    <Box>
        <Box
            sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'left',
                    overflow: 'auto',
                    ml: ml || 'auto'
            }}
        >
            <PieChart
                series={[
                    {
                        data: data.map((item, index) => ({
                            id: `severity,${index}`,
                            value: item.value,
                            label: item.label,
                            color: colors[index % 4],
                        })),
                        outerRadius: outerRadius,
                        cornerRadius: 4,
                        highlightScope: { fade: 'series', highlight: 'item' },
                    },
                ]}
                width={width}
                height={height}
                slotProps={{
                    legend: {
                        direction: 'column',
                        position: { vertical: 'middle', horizontal: 'right' },
                        labelStyle: {
                            fontSize: 14,
                            fill: 'white',
                        },
                    },
                }}
            />
        </Box>
    </Box>
  );
};

export default NormalPieChart;
