// Copyright (C) CELSOS. All rights reserved.
// Material-UI Components
import { Box } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const NormalBarChart = ({ height, width, data, colors }) => {
  const valueFormatter = (value) => `${value} modules`;

  const otherSetting = {
    height: height,
    width: width,
    yAxis: [
        {
            label: 'Affected modules', 
            labelStyle: {
                fill: '#ffffff',
                fontWeight: 'bold',
            },
        }
    ],
    margin: {
        left: 80, // Aumenta el margen izquierdo para evitar la superposición
    },
    grid: { horizontal: true},
  };

  return (
    <Box sx={{ ml: 12}}>
        <BarChart
        dataset={data}
        xAxis={[
            {
            scaleType: 'band',
            dataKey: 'label',
            colorMap: {
                type: 'ordinal',
                colors: colors,
            },
            },
        ]}
        series={[
            {
            dataKey: 'value',
            valueFormatter,
            },
        ]}
        {...otherSetting}
        sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translate(-25px, 0)',
            },
            '& .MuiChartsAxis-left .MuiChartsAxis-tickLabel, & .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
              strokeWidth: 1,
              fill: '#ffffff',
            },
            '& .MuiChartsAxis-left .MuiChartsAxis-tick, & .MuiChartsAxis-bottom .MuiChartsAxis-tick': {
              strokeWidth: 1,
              stroke: '#ffffff',
            },
            '& .MuiChartsAxis-left .MuiChartsAxis-line, & .MuiChartsAxis-bottom .MuiChartsAxis-line': {
              stroke: '#ffffff',
              strokeWidth: 0.6,
            },
          }}
        slotProps={{
            legend: {
                hidden: true
            }
          }}
        />
    </Box>
  );
};

export default NormalBarChart;
