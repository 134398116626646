// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Copyright (C) CELSOS. All rights reserved.*/
@keyframes fadeIn {
    from {
      opacity: 0;
      color: rgba(255, 255, 255, 0); /* Color inicial */
    }
    to {
      opacity: 1;
      color: #656aff; /* Color final */
    }
  }
  
  .fade-in {
    opacity: 0;
    color: rgba(255, 255, 255, 0); /* Color inicial */
    animation: fadeIn 1s ease-in forwards;
  }`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI;MACE,UAAU;MACV,6BAA6B,EAAE,kBAAkB;IACnD;IACA;MACE,UAAU;MACV,cAAc,EAAE,gBAAgB;IAClC;EACF;;EAEA;IACE,UAAU;IACV,6BAA6B,EAAE,kBAAkB;IACjD,qCAAqC;EACvC","sourcesContent":["/* Copyright (C) CELSOS. All rights reserved.*/\n@keyframes fadeIn {\n    from {\n      opacity: 0;\n      color: rgba(255, 255, 255, 0); /* Color inicial */\n    }\n    to {\n      opacity: 1;\n      color: #656aff; /* Color final */\n    }\n  }\n  \n  .fade-in {\n    opacity: 0;\n    color: rgba(255, 255, 255, 0); /* Color inicial */\n    animation: fadeIn 1s ease-in forwards;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
