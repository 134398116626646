// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React from 'react';

// Material-UI Components
import { Tabs, Tab, Box } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import TuneIcon from '@mui/icons-material/Tune';
import TableViewIcon from '@mui/icons-material/TableView';

const ProjectTabs = ({ tabIndex, handleTabChange }) => {
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Project Tabs">
        <Tab 
          icon={<TableViewIcon />} 
          sx={{
            fontFamily: 'silkaregular',
            color: 'white', // Color del texto del tab inactivo
            backgroundColor: '#292767',
            '&.Mui-selected': {
              backgroundColor: '#656cf6', // Color del texto del tab activo
              color: 'white'
            },
            ":hover":{
                backgroundColor:'#656cf6aa'
            }
          }} 
        />
        <Tab 
          icon={<QueryStatsIcon />} 
          sx={{
            fontFamily: 'silkaregular',
            color: 'white', // Color del texto del tab inactivo
            backgroundColor: '#292767',
            '&.Mui-selected': {
              backgroundColor: '#656cf6', // Color del texto del tab activo
              color: 'white'
            },
            ":hover":{
                backgroundColor:'#656cf6aa'
            }
          }} 
        />
        <Tab 
          icon={<TuneIcon />} 
          sx={{
            fontFamily: 'silkaregular',
            color: 'white', // Color del texto del tab inactivo
            backgroundColor: '#292767',
            '&.Mui-selected': {
              backgroundColor: '#656cf6', // Color del texto del tab activo
              color: 'white',
            },
            ":hover":{
                backgroundColor:'#656cf6aa'
            }
          }} 
        />
      </Tabs>
    </Box>
  );
};

export default ProjectTabs;
