// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';

// Material-UI Components
import { Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const ImageSidebarLeft = ({ rows, onRowClick }) => {
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'accepted', headerName: 'Accepted', flex: 0 },
    {
      field: 'image',
      headerName: 'Image',
      flex: 1,
      renderCell: (params) => (
        <img src={params.row.image} alt="Module" style={{ width: '100px' }} />
      ),
    },
  ];

  return (
    <div className="image-sidebar left">
      <Paper sx={{ height: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          onRowClick={onRowClick}
          rowHeight={70}
        />
      </Paper>
    </div>
  );
};

export default ImageSidebarLeft;
