// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Material-UI Components
import { Button } from '@mui/material';

// Custom Components
import CustomTextField from '../components/UI/CustomTextField';
import Footer from '../components/Layout/Global/Footer';


const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
        setError('Please enter a valid email address.');
        return;
      }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    // try {
    //   setError('');
    //   // Llama a la función register del AuthContext para registrar el usuario
    //   await register(email, password, confirmPassword);
    //   // Si el registro es exitoso, redirige a la página de inicio o dashboard
    //   navigate('/');
    // } catch (err) {
    //   // Manejo de errores, si falla el registro
    //   setError('Error en el registro. Inténtalo de nuevo.');
    // }
  };

  return (
    <div className="page-container">
        <div className="content">

            <div className="register">
                <h2>CREATE YOUR ACCOUNT</h2>
                {error && <p style={{ color: 'red' }}>{error}</p>} {/* Muestra el error si lo hay */}

                <form onSubmit={handleSubmit}>
                <div>
                    <CustomTextField
                    label="e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Actualizar el estado con el valor del input
                    />
                </div>
                <div>
                    <CustomTextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} // Actualizar el estado con el valor del input
                    />
                </div>
                <div>
                    <CustomTextField
                    label="Repeat password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)} // Actualizar el estado con el valor del input
                    />
                </div>
                <div>
                <Button
                    variant="contained"
                    tabIndex={-1}
                    sx={{ fontSize: '120%', fontFamily: 'silkaregular' }}
                >
                    CAPTCHA
                </Button>
                </div>
                <Button
                    variant="contained"
                    tabIndex={-1}
                    sx={{ fontSize: '120%', fontFamily: 'silkaregular' }}
                    type="submit"
                >
                    Register
                </Button>
                </form>
            </div>
        </div>
        <Footer />
    </div>
  );
}

export default Register;
