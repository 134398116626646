// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React from 'react';

// Material-UI Components
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NavigationButtons = ({ isProjectsActive, isCriteriaActive, isFeedbackActive }) => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Button
                variant="contained"
                onClick={() => navigate('/home')}
                sx={{
                    background: isProjectsActive ? 'primary' : '#000000',  // Color condicional
                    textTransform: 'capitalize',
                    fontWeight: isProjectsActive ? 'bold' : 'normal', // Negrita si está activo
                    mr: '5px',
                }}
            >
                <Typography sx={{ fontSize: '160%' }}>
                    My Projects
                </Typography>
            </Button>

            <Button
                variant="contained"
                onClick={() => navigate('/criteria')}
                sx={{
                background: isCriteriaActive ? 'primary' : '#000000',  // Color condicional
                textTransform: 'capitalize',
                fontWeight: isCriteriaActive ? 'bold' : 'normal', // Negrita si está activo
                }}
            >
                <Typography sx={{ fontSize: '160%' }}>
                    My Criteria
                </Typography>
            </Button>

            <Button
                variant="contained"
                onClick={() => navigate('/feedback')}
                sx={{
                    background: isFeedbackActive ? 'primary' : '#000000',  // Color condicional
                    textTransform: 'capitalize',
                    fontWeight: isFeedbackActive ? 'bold' : 'normal', // Negrita si está activo
                    mr: '5px',
                }}
            >
                <Typography sx={{ fontSize: '160%' }}>
                    Send Feedback
                </Typography>
            </Button>

            <Box sx={{ flexGrow: 1 }} />
            <div className='header-links'>
                <a
                    href="https://www.celsospv.com/"
                    target='_blank'
                    sx={{ textTransform: 'capitalize', mr: 2}}
                >
                    <Typography variant='h5'>
                        Visit our website
                    </Typography>
                </a>
            </div>
        </React.Fragment>
    );
};

export default NavigationButtons;
