// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material-UI Components
import { Button, Typography, Modal, Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

// Contexts
import { useCriteria } from '../../contexts/CriteriaContext';

// Services
import ProjectService from '../../services/ProjectService';

// Components
import CustomTextField from '../UI/CustomTextField';
import InfoTooltip from './InfoTooltip';

// Styles
import '../../assets/styles/pages/create.css';
import DimensionsTextBox from './DimensionsTextBox';

const CreateModal = ({ open, handleClose }) => {
    const [step, setStep] = useState(1);

    const [projectName, setProjectName] = useState('');
    const [desc, setDesc] = useState('');
    const [criteria, setCriteria] = useState(0);
    const [tech, setTech] = useState('');
    const [error, setError] = useState('');
    const [rows, setRows] = useState('');
    const [columns, setColumns] = useState('');
    const [height, setHeight] = useState('');
    const [width, setWidth] = useState('');
    const [criteriaNames, setCriteriaNames] = useState(['No criteria']);
    const [criteriaIds, setCriteriaIds] = useState(['']);

    const navigate = useNavigate();
    const { fetchCriteria } = useCriteria();

    useEffect(() => {
        const getCriteria = async () => {
            const criteriaList = await fetchCriteria();
            setCriteriaIds(criteriaList.map(item => item[0]));
            setCriteriaNames(criteriaList.map(item => item[1]));
        };
        getCriteria();
    }, []);

    useEffect(() => {
        setStep(1);
    }, [open]);

    const handleNext = () => {
        if (!projectName) {
            setError('Please complete all fields before proceeding.');
            return;
        }
        setError('');
        setStep(2);
    };

    const handleBack = () => setStep(1);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setError('');
            await ProjectService.create(projectName, desc, criteria, tech, rows, columns, height, width);
            navigate(`/projects/${projectName}`);
        } catch (err) {
            setError(err.response?.data?.detail || "Error during project creation, try again later");
        }
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title">
            <Box className="wide-modal">
            <form onSubmit={handleSubmit}>
                <Typography variant="h4" color="primary" fontWeight="bold">
                    CREATE A NEW PROJECT
                </Typography>
                {error && <p style={{ color: 'red' }}>
                    {typeof error === 'object' ?
                        'There was an unexpected error, please make sure all fields are filled in'
                        : 
                        error
                    }
                  </p>
                }


                {step === 1 ? (
                    <>
                        <Typography variant="h5" color="primary">Project info</Typography>
                        <div width="140%" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CustomTextField 
                                label="Project name" 
                                required 
                                value={projectName} 
                                onChange={(e) => setProjectName(e.target.value)}
                                inputProps={{ maxLength: 30 }} 
                            />
                            <InfoTooltip 
                            label={"The name you choose for your project will serve as \
                                    its unique identifier. It must be distinctive and \
                                    not already in use to ensure clarity and originality."}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CustomTextField
                                id="outlined-multiline-static"
                                label="Description"
                                multiline
                                rows={4}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                                inputProps={{ maxLength: 256 }}
                            />
                            <InfoTooltip 
                            label={"The description of your project should clearly outline \
                                    its purpose, goals, and key features. It must be detailed \
                                    enough to provide a comprehensive understanding, yet concise, \
                                    to capture attention and convey its uniqueness effectively."
                                   }/>
                        </div>
                        <Button variant="contained" sx={{ mt: 2 }} onClick={handleNext}>
                            Next
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography variant="h5" color="primary">Modules info</Typography>
                        
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <FormControl required sx={{ m: 1, minWidth: '85%' }}>
                                <InputLabel id="demo-simple-select-helper-label" sx={{ color: '#656CF6', fontFamily: 'silkaregular' }}>Tech type</InputLabel>
                                <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={tech}
                                
                                label="tech"
                                onChange={(e) => setTech(e.target.value)}
                                >
                                    <MenuItem value={'Monocrystalline'}>Monocrystalline</MenuItem>
                                    <MenuItem value={'Polycrystalline'}>Polycrystalline</MenuItem>
                                </Select>
                            </FormControl>
                            <InfoTooltip label="Select the appropriate technology type for the modules in your project"/>
                        </div>
                        <DimensionsTextBox 
                        label={"Rows represent the horizontal arrangement of solar cells, \
                                while columns indicate the vertical alignment within each \
                                row when the module is placed horizontally."
                              } 
                        setSmall={setRows} 
                        setBig={setColumns} 
                        rows={rows} 
                        columns={columns}
                        smallLabel={"Rows"}
                        bigLabel={"Columns"}
                        unit={"cells"} 
                        />
                        
                        <Typography variant="h5" color="primary">Cell info</Typography>
                        <DimensionsTextBox 
                        label={"The width refers to the horizontal dimension of the cell,\
                                and the height refers to the vertical dimension when the \
                                module is positioned horizontally."} 
                        setSmall={setWidth} 
                        setBig={setHeight} 
                        rows={width} 
                        columns={height}
                        smallLabel={"Width"}
                        bigLabel={"Height"}    
                        unit={"mm"}        
                        />
                        <Box display="flex" justifyContent="space-between" mt={2}>
                            <Button variant="outlined" onClick={handleBack}>
                                Back
                            </Button>
                            <Button variant="contained" type="submit" style={{ marginLeft: '2%' }}>
                                Create Project
                            </Button>
                        </Box>
                    </>
                )}
                </form>
            </Box>
        </Modal>
    );
};

export default CreateModal;
