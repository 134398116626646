// Copyright (C) CELSOS. All rights reserved.
import HttpService from './HTTPService'

import config from '../Config';

const { BACKEND_URL } = config;

const API_URL = `${BACKEND_URL}/api/modules`;

const getModule = async (projectName, moduleId) => {
    try {
        const response = await HttpService.getAxiosClient().get(`${API_URL}/get_image/${projectName}?img=${moduleId}`);

        const binaryData = atob(response.data.image);

        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: "image/png" });

        const url = URL.createObjectURL(blob)
        const defectData = response.data.metadata
        return {url, defectData};
    } catch (error) {
        console.error('Error fetching modules list');
    }
}

const getModuleThumbnail = async (projectName, moduleId) => {
    try {
        const response = await HttpService.getAxiosClient().get(`${API_URL}/get_image_thumbnail/${projectName}?img=${moduleId}`);

        const binaryData = atob(response.data.image);

        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: "image/png" });

        const url = URL.createObjectURL(blob)
        const defectData = response.data.metadata
        return {url, defectData};
    } catch (error) {
        console.error('Error fetching modules list');
    }
}

const getAllModules = async (projectId) => {
    try {
        const formData = new FormData();
        formData.append('project_id', projectId);
        const response = await HttpService.getAxiosClient().post(`${API_URL}/get_all_modules`, formData, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching modules list');
    }
}

const deleteSetModules = async (projectId, moduleList) => {
    try {
        const formData = new FormData();
        formData.append('project_id', projectId);
        formData.append('module_list', JSON.stringify(moduleList));
        const response = await HttpService.getAxiosClient().post(`${API_URL}/delete_set_modules`, formData, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching modules list');
    }
}

const analyzeSingleModule = async (projectId, moduleId) => {
    try {
        const formData = new FormData();
        formData.append('project_id', projectId);
        formData.append('module_id', moduleId)
        await HttpService.getAxiosClient().post(`${API_URL}/analyze_single_module`, formData, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
    } catch (error) {
        console.error('Error fetching modules list');
    }
}

const analyzeSetModules = async (projectId, moduleList) => {
    try {
        const formData = new FormData();
        formData.append('project_id', projectId);
        formData.append('module_list', JSON.stringify(moduleList));
        const response = await HttpService.getAxiosClient().post(`${API_URL}/analyze_set_modules`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data
        // return response.data;
    } catch (error) {
        console.error('Error during inference criteria');
    }
}

export default {
    getModule,
    getModuleThumbnail,
    getAllModules,
    deleteSetModules,
    analyzeSingleModule,
    analyzeSetModules
};
