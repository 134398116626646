// Copyright (C) CELSOS. All rights reserved.
import React, { useState } from 'react';

// Material-UI components
import { Typography, LinearProgress, Box, Divider, styled } from '@mui/material';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';

// Styles
import '../../assets/styles/components/dragNDrop.css';

const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  position: 'absolute',
  width: 0,
  height: 0,
  opacity: 0,
});

const DragNDrop = ({ setFiles }) => {
  const [dragOver, setDragOver] = useState(false);
  const [fileProgress, setFileProgress] = useState({}); // Estado para progreso de cada archivo

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    uploadWithProgress(files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    uploadWithProgress(files);
    setDragOver(false);
  };

  // Función para manejar la subida y el progreso
  const uploadWithProgress = (files) => {
    const updatedProgress = {};
    files.forEach((file) => {
      updatedProgress[file.name] = 0; // Inicializa progreso en 0
    });
    setFileProgress(updatedProgress);

    // Llamamos a `setFiles` y actualizamos el progreso de cada archivo
    setFiles(files, (file, progress) => {
      setFileProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: progress,
      }));
    });
  };

  return (
    <div className='columns'>
        <div className='column-left'>
            <label
            className="upload-field"
            onDragOver={(e) => {
                e.preventDefault();
                setDragOver(true);
            }}
            onDragEnter={(e) => {
                e.preventDefault();
                setDragOver(true);
            }}
            onDragLeave={() => setDragOver(false)}
            onDrop={handleDrop}
            style={{
                border: dragOver ? '2px dashed #000' : '2px dashed #656cf6',
            }}
            >
                <VisuallyHiddenInput
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    accept="image/png, image/jpeg, image/jpg"
                />
                <Typography variant="h5" color="#000">
                    Drag & drop your files here or click to browse
                </Typography>
                <div className="text-columns">
                    <div className="text-column-left">
                    <Typography sx={{ fontSize: '80%', fontWeight:'bold' }} color="#000">
                        Supported image types
                    </Typography>
                    </div>
                    <div className="text-column-right">
                    <Typography sx={{ fontSize: '80%', fontWeight:'bold' }} color="#000">
                        jpeg, jpg, png
                    </Typography>
                    </div>
                </div>
                <UploadFileRoundedIcon className='background-icon' />
                
                {/* Listado de archivos con barra de progreso */}
            </label>
        </div>
        <Divider orientation="vertical" variant="middle" flexItem />
        <div className='column-right'>
            <Typography color="#656af6" sx={{ml:"10%", mb:"10px"}}>Completed uploads</Typography>
            <div className='column-right content'>
                {Object.entries(fileProgress).map(([fileName, progress]) => (
                    <Box key={fileName} sx={{ marginTop: 2 }}>
                        <Typography variant="body2" color="textSecondary">
                            {fileName}
                        </Typography>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                ))}
            </div>
        </div>
    </div>
  );
};

export default DragNDrop;
