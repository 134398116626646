// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';

// Styles
import '../assets/styles/pages/profile.css';
import '../assets/styles/pages/ProfilePage/accountInfo.css';

// Components
import AccountInfo from '../components/Layout/UserSettings/AccountInfo';
import PaymentMethod from '../components/Layout/UserSettings/PaymentMethod';
import Subscription from '../components/Layout/UserSettings/Subscription';

// Material-UI Components
import { Typography } from '@mui/material';
import Footer from '../components/Layout/Global/Footer';


const Profile = () => {
    return(
        <div className="page-container">
            <div className="content">
                <div className='container'>
                    <div className='account-info'>
                        <AccountInfo/>
                    </div>
                    <div className='subscription'>
                        <Subscription/>
                    </div>
                    <div className='payment-method'>
                        <PaymentMethod/>
                    </div>
                    
                    <div className='notifications'>
                    <Typography> aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa {'(:'}</Typography>
                    
                    </div>
                    
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;
