// Copyright (C) CELSOS. All rights reserved.
import React, { useState } from 'react';

// External libraries
import { useNavigate } from 'react-router-dom';

// Material-UI components
import { Card, CardContent, Typography, CardActionArea, Box, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { RemoveCircleOutline, MoreHoriz, Settings, Delete, PushPin, PushPinOutlined } from '@mui/icons-material';

// Contexts
import { useProject } from '../../contexts/ProjectContext';

// Components
import DeleteModal from './DeleteModal';

const ProjectComponent = ({ projectId, title, completedTasks, description, date, onClick, isPinned, togglePinProject }) => {
    const navigate = useNavigate();
    const {project, deleteProject, error } = useProject();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    


    // Abrir menú
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Cerrar menú
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    
    const handleCloseReload = () => {
        setOpenDelete(false)
        window.location.reload()
    };

    const handleClose = () => {
        setOpenDelete(false)
    }

    const rmProject = (projectId) => {
        deleteProject(projectId)
        handleCloseReload()
    }
   

    return (
        <>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: 1.5 }}>
                {/* Fija la altura mínima */}
                <CardActionArea sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }} disableRipple onClick={onClick}>
                    <Box sx={{ width: "100%", backgroundColor: '#656aff', padding: '8px 16px' }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ ml: 1 }}>
                            <Typography variant="h6" color="#ffffff" sx={{ textTransform: 'capitalize', textAlign: 'left' }}>
                                {title}
                            </Typography>
                            <Box display="flex" alignItems="center" sx={{ mr: -1.25 }}>
                                <MenuItem variant="body2" style={{ width:'10px', justifyContent:'center' }} onClick={(e) => {
                                                e.stopPropagation(); // Evita que el evento active otras acciones
                                                togglePinProject(projectId);
                                            }}>
                                    {isPinned ? (
                                        <PushPin
                                            fontSize="small"
                                            
                                        />
                                    ) : (
                                        <PushPinOutlined
                                            fontSize="small"
                                        />
                                    )}
                                </MenuItem>

                                {/* Botón clickeable para el ícono */}
                                <MenuItem style={{ width:'10px', justifyContent:'center', marginRight:'11px' }}
                                    onClick={(event) => {
                                        event.stopPropagation(); // Evita que active el clic del CardActionArea
                                        handleMenuOpen(event);
                                    }}
                                    sx={{ color: '#ffffff', borderRadius:1.5 }}
                                >
                                    <MoreHoriz fontSize="medium" />
                                </MenuItem>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
                                <Typography variant="body2" style={{ fontSize: '14px' }}>
                                    {completedTasks} images uploaded
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
                                <Typography variant="body2" style={{ fontSize: '14px' }}>
                                    Status:
                                </Typography>
                                <RemoveCircleOutline fontSize="small" sx={{ marginLeft: 1 }} />
                            </Box>
                        </Box>
                    </Box>

                    <CardContent sx={{ flexGrow: 1, display: 'flex' }}>
                        {/* Descripción con puntos suspensivos al ser muy larga */}
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                flexGrow: 1, // Deja que la descripción ocupe el espacio disponible
                                textAlign: 'left',
                            }}
                        >
                            {description}
                        </Typography>
                    </CardContent>

                    {/* Fecha alineada a la derecha en la parte inferior */}
                    <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ marginBottom: '5px', ml: "70%", marginTop: 'auto' }}>
                        <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'left' }}>
                            {date}
                        </Typography>
                    </Box>
                </CardActionArea>

                {/* Menu */}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={() => navigate(`/Projects/${title}`, { state: { tabIndex: 2 } })}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                    <MenuItem onClick={() => setOpenDelete(true)}>
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        Delete
                    </MenuItem>
                </Menu>
            </Card>

            <DeleteModal open={openDelete} handleClose={handleClose} deleteData={projectId} deleteMethod={rmProject} />
        </>
    );
};

export default ProjectComponent;