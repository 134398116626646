// Copyright (C) CELSOS. All rights reserved.
const HoverableIcon = ({ IconComponent, hoverColor, onClick }) => (
    <IconComponent 
        sx={{ 
            fontSize: '30px', 
            cursor: 'pointer', 
            color: 'inherit', 
            transition: 'color 0.3s ease',
            '&:hover': {
                color: hoverColor,
            }
        }}
        onClick={onClick}
    />
);

export default HoverableIcon