// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Copyright (C) CELSOS. All rights reserved. */
.limited-container {
    width: 80%; /* Define el ancho máximo */
    height: 80%; /* Define el alto máximo */
    overflow: hidden; /* Oculta contenido que desborde */
    position: relative; /* Para manejar contenido posicionado */
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/pages/mainPage.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;IACI,UAAU,EAAE,2BAA2B;IACvC,WAAW,EAAE,0BAA0B;IACvC,gBAAgB,EAAE,kCAAkC;IACpD,kBAAkB,EAAE,uCAAuC;IAC3D,iBAAiB;AACrB","sourcesContent":["/* Copyright (C) CELSOS. All rights reserved. */\n.limited-container {\n    width: 80%; /* Define el ancho máximo */\n    height: 80%; /* Define el alto máximo */\n    overflow: hidden; /* Oculta contenido que desborde */\n    position: relative; /* Para manejar contenido posicionado */\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
