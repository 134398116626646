// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';

// Material-UI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const RoundedTypography = ({ borderRadius, padding, backgroundColor, color, fontSize, icon, children }) => {
  return (
    <Box
    sx={{
        borderRadius: borderRadius || '10px',
        border: 3,
        borderColor: color || null,
        backgroundColor: backgroundColor || '#f0f0f0',
        padding: padding || '2px',
        display: 'inline-block',
        fontSize: fontSize || 'inherit', // Default value if not provided
        marginTop: icon ? '-5px' : '-40px'
      }}
    >
        <div
            style={{ display:'flex',
                flexDirection:'row',
                padding: 3 }}>
        {icon ? icon : <></>}
        <Typography color={color || 'primary'} fontSize={fontSize} marginLeft={0.5} marginRight={1}>{children}</Typography>
        </div>
    </Box>
  );
};

export default RoundedTypography;