// Copyright (C) CELSOS. All rights reserved.
import { useAuth } from '../../contexts/AuthContext';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, keycloak } = useAuth();

  if (!isAuthenticated) {
    keycloak && keycloak.login();
    return null;
  }

  return children;
};

export default PrivateRoute;
