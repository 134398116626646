// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("silka-regular-webfont.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
Copyright (C) CELSOS. All rights reserved.
! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 10, 2018
*/
@font-face {
    font-family: 'silkaregular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;

}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;IACI,2BAA2B;IAC3B,+DAAwD;IACxD,mBAAmB;IACnB,kBAAkB;;AAEtB","sourcesContent":["/*\nCopyright (C) CELSOS. All rights reserved.\n! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 10, 2018\n*/\n@font-face {\n    font-family: 'silkaregular';\n    src: url('silka-regular-webfont.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
