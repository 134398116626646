// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Material-UI Components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

// Material-UI Icons
import AddBoxIcon from '@mui/icons-material/AddBox';

// Contexts
import { useProject } from '../contexts/ProjectContext';

// Components
import ProjectComponent from '../components/Feature/ProjectComponent';
import LoadingScreen from '../components/Layout/Global/LoadingScreen';
import CreateModal from '../components/Feature/CreateModal';

// Styles
import '../assets/styles/pages/home.css';
import { useUser } from '../contexts/UserContext';


/**
 * Home component that displays a grid of projects and allows users to create new projects,
 * view project details, and pin/unpin projects. It fetches project data, module counts, 
 * and pinned projects from the backend and manages their state.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered Home component.
 *
 * @example
 * <Home />
 *
 * @description
 * - Fetches all projects and their module counts on initial render.
 * - Fetches pinned projects and synchronizes them with the backend.
 * - Allows users to pin/unpin projects, and updates the backend accordingly.
 * - Displays a button to navigate to the "Create Project" page.
 * - Projects are sorted by pinned status and creation date.
 *
 * @dependencies
 * - `useNavigate` from `react-router-dom` for navigation.
 * - `useState`, `useEffect`, and `useRef` from `react` for state and lifecycle management.
 * - `useUser` and `useProject` custom hooks for fetching user and project data.
 * - `Button`, `Box`, `Typography`, and `AddBoxIcon` from Material-UI for UI components.
 * - `ProjectComponent` for rendering individual project details.
 * - `LoadingScreen` for displaying a loading state.
 *
 * @state
 * @property {Array} projects - List of all projects fetched from the backend.
 * @property {Object} moduleCounts - Stores the module count for each project.
 * @property {boolean} isAppReady - Indicates whether the app has finished loading initial data.
 * @property {Array} pinnedProjects - List of pinned project IDs.
 *
 * @methods
 * @function getModules - Fetches and updates the module count for a specific project.
 * @function togglePinProject - Toggles the pinned status of a project.
 *
 * @effects
 * - Fetches all projects and their module counts on mount.
 * - Fetches pinned projects on mount and synchronizes them with the backend.
 * - Saves pinned projects to the backend on unmount or when pinnedProjects state changes.
 */

const Home = () => {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const { fetchPinnedProjects, savePinnedProjects } = useUser();
    const { fetchAllProjects, fetchModuleCount } = useProject();
    const [moduleCounts, setModuleCounts] = useState({}); // Almacena el conteo de módulos de cada proyecto
    const [isAppReady, setIsAppReady] = useState(false); // Nuevo estado para controlar la carga inicial
    const [pinnedProjects, setPinnedProjects] = useState([]);
    const [openCreate, setOpenCreate] = useState(false);
    const pinnedProjectsRef = useRef([]);

    // Obtiene y almacena los módulos de cada proyecto
    const getModules = async (projectId) => {
        try {
            const data = await fetchModuleCount(projectId);
            setModuleCounts(prevCounts => ({
                ...prevCounts,
                [projectId]: data // Actualiza solo el conteo del proyecto correspondiente
            }));
        } catch (error) {
            console.error('Error fetching module count: ', error);
        }
    };

    const handleClose = () => {
        setOpenCreate(false)
    }

    // Llamada para obtener todos los proyectos
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchAllProjects();
                setProjects(response);

                // Llama a getModules para cada proyecto solo una vez
                await Promise.all(response.map(project => getModules(project.id)));
                setIsAppReady(true);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };
        fetchData();

        return () => {
            // Usa el valor más reciente de pinnedProjects almacenado en la ref
            savePinnedProjects(pinnedProjectsRef.current);
        };
    }, []);

    useEffect(() => {
        const getPinnedProjects = async () => {
          try {
            const projects = await fetchPinnedProjects();
            setPinnedProjects(projects); // Establece los proyectos anclados cuando la llamada haya terminado
            pinnedProjectsRef.current = projects; // Actualiza la referencia también
          } catch (error) {
            console.error("Error fetching pinned projects:", error);
          }
        };
    
        getPinnedProjects(); // Llama a la función asincrónica
      }, [fetchPinnedProjects]);

    useEffect(() => {
        pinnedProjectsRef.current = pinnedProjects; // Sincroniza la ref con el estado actual
        savePinnedProjects(pinnedProjectsRef.current);
    }, [pinnedProjects]);

    const togglePinProject = (projectId) => {
        setPinnedProjects((prev) => {
            if (prev.includes(projectId)) {
                return prev.filter((id) => id !== projectId); // Quita el proyecto si ya está pineado
            } else {
                return [...prev, projectId]; // Añade el proyecto si no está pineado
            }
        });
    };

    const sortedProjects = [...projects]
    .sort((a, b) => new Date(b.date) - new Date(a.date)) // Ordena por fecha
    .sort((a, b) => (pinnedProjects.includes(b.id) - pinnedProjects.includes(a.id))); // Ordena los pineados primero

    return (
        <>
            {isAppReady ?
                <div className='grid-container'>
                    <div className='grid-item'>
                        <Button
                            sx={{
                            borderRadius: 2,
                            backgroundColor: '#1a1949',
                            color: '#656aff',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center', // Centra verticalmente el contenido
                            justifyContent: 'center', // Centra horizontalmente el contenido
                            }}
                            onClick={() => setOpenCreate(true)}
                        >
                            <Box>
                                <AddBoxIcon sx={{ marginLeft: '5%', fontSize: 70, color: 'main' }} /> {/* Tamaño del icono */}
                                <Typography sx={{ fontWeight: 'bold' }}>Create Project</Typography>
                            </Box>
                        </Button>
                    </div>
                    {sortedProjects.map((project) => (
                        <div className='grid-item' key={project.id}>
                            <ProjectComponent
                                projectId={project.id}
                                title={project.name}
                                completedTasks={moduleCounts[project.id] || 0}
                                description={project.desc}
                                date={project.date}
                                onClick={() => navigate(`/Projects/${project.name}`)}
                                isPinned={pinnedProjects.includes(project.id)} // Nuevo prop
                                togglePinProject={togglePinProject} // Nuevo prop
                            />
                        </div>
                    ))}
                    <CreateModal open={openCreate} handleClose={handleClose} />
                </div>
            :
                <LoadingScreen />
            }
            {/* <Footer /> */}
        </>
    );
};

export default Home;
