import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, InputLabel, FormControl } from '@mui/material';


const CheckboxSelect = ({ defects, disabledDefects }) => {
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        disabledDefects(selectedNames);
    }, [selectedValues]);

    // Defects object example: { 0: 'Crack', 1: 'Dent', 2: 'Scratch' }
    const defectOptions = Object.entries(defects).map(([key, value]) => ({
        value: parseInt(key, 10), // Convert string key to a number
        label: value,
    }));

    // Handle selection change
    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValues(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    // Map the selected values to names
    const selectedNames = selectedValues.map((id) => {
        const option = defectOptions.find((option) => option.value === parseInt(id, 10));
        return option ? option.label : '';
    });

    return (
        <FormControl sx={{ width: '200px', ml: 3, mt: -0.5 }} variant='filled'>
            <InputLabel
                sx={{
                    mt: -0.5,
                    fontSize: '14px',
                    top: '-4px', // Ajuste de posición
                    color: '#656CF6',
                    '&.Mui-focused': {
                        mt: 0,
                        color: '#656CF6',
                    },
                }}
            >
                Hide Defects
            </InputLabel>
            <Select
                multiple
                value={selectedValues}
                onChange={handleChange}
                renderValue={() => selectedNames.join(', ')}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            backgroundColor: '#1b1b49',
                            color: '#656CF6',
                        },
                    },
                    sx: {
                        '&& .Mui-selected': {
                            color: '#656CF6',
                            background: '#1b1b49',
                        },
                    },
                }}
                sx={{
                    fontFamily: 'silkaregular',
                    backgroundColor: '#1b1b49',
                    color: '#656CF6',
                    fontSize: '14px', // Ajusta el tamaño de texto
                    height: '32px', // Ajusta la altura total del Select
                    lineHeight: '32px', // Asegura que el texto se alinee verticalmente
                    padding: '0 12px', // Reduce el padding interno
                    '&:hover': {
                        backgroundColor: '#29295c',
                    },
                    '&.Mui-focused': {
                        backgroundColor: '#29295c',
                    },
                    '& .MuiSelect-icon': {
                        color: '#656CF6',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#656CF6', // Borde más fino o compacto si es necesario
                    },
                }}
            >
                {defectOptions.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{
                            fontSize: '14px', // Ajusta el tamaño del texto en las opciones
                            fontFamily: 'silkaregular',
                            backgroundColor: '#1b1b49',
                            color: '#656CF6',
                            '&.Mui-selected': {
                                backgroundColor: '#252567',
                                color: '#656CF6',
                            },
                            '&:hover': {
                                backgroundColor: '#29295c',
                            },
                        }}
                    >
                        <Checkbox
                            checked={selectedValues.includes(option.value)}
                            sx={{
                                padding: '4px', // Reduce el espacio alrededor del checkbox
                            }}
                        />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CheckboxSelect;
