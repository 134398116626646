// Copyright (C) CELSOS. All rights reserved.
import React, { useState } from 'react';

//External Libraries
import { Carousel } from "react-responsive-carousel";
import { Typography } from '@mui/material';

// Styles
import '../../assets/styles/components/imageCarousel.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const handleOnChange = (index) => {
        setCurrentIndex(index);
    };

    const getUrls = (index) => {
        switch (index) {
            case 0:
                return "https://www.celsospv.com/el-kit/"
            case 1:
                return "https://www.celsospv.com/aerial-inspection/"
            default:
                return "/"
        }
    }

    const openWindow = (index) =>{
        window.open(getUrls(index), '_blank')
    }
    

    return (
        <div 
        style={{ cursor: 'pointer' }} 
        className="image-carousel-container">
            <Carousel
            showThumbs={false}
            autoPlay={true}
            interval={6000}
            infiniteLoop={true}
            showStatus={false}
            showIndicators={true}
            className="image-carousel"
            onClickItem={() => openWindow(currentIndex)}
            onChange={handleOnChange}
            >
                {images.map((imageUrl, index) => (
                    <div key={index}>
                        <img src={imageUrl} alt={`slide-${index}`} />
                        <Typography align='left' sx= {{ backgroundColor:'#000007', color:'#656CF6',  fontFamily:'silkaregular'}}></Typography>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default ImageCarousel;
