// Copyright (C) CELSOS. All rights reserved.
import HttpService from './HTTPService'

import config from '../Config';

const { BACKEND_URL } = config;

const API_URL = `${BACKEND_URL}/api/criteria`;


const create = async () => {
    try {
      // Crear los datos en formato "form-data"
      const response = await HttpService.getAxiosClient().post(`${API_URL}/create`);
      return response.data

    } catch (error) {
        console.error('Error during project creation', error.response.detail);
        throw error;
    }
};

const getCriteria = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(`${API_URL}/get_criteria_list`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user criteria list');
    }
  };
  

export default {
  create,
  getCriteria
};
