// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material-UI Components
import { Box, Typography } from '@mui/material';
import Footer from '../components/Layout/Global/Footer';



const NotFound = () => {
    const navigate = useNavigate();

    // Redirigir después de 10 segundos
    useEffect(() => {
        const timer = setTimeout(() => {
        navigate('/'); // Redirige a la página de inicio
        }, 1500); // 10000 ms = 10 segundos

        // Limpiar el temporizador si el componente se desmonta
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="page-container">
            <div className="content">
                <h1>Error 404</h1>
                <Typography color={'white'}>We're sorry, the page you are looking for no longer exists</Typography>
                <Typography color={'white'}>You will be redirected soon...</Typography>
                <Box mt='29.57%' ></Box>
            </div>
            <Footer/>
        </div>
    );
}

export default NotFound;
