// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Material-UI Components
import { Box, LinearProgress, Typography, Button } from '@mui/material';

// Components
import InputFileUpload from '../components/Feature/InputFileUpload';
import RandomTextEffect from '../components/UI/TextFadeIn';
import ImageDisplay from '../components/Layout/Visualizer/ImageDisplay';
import ImageCarousel from '../components/Feature/ImageCarousel';

// Utilities
import { getDefectStyles } from '../utils/DefectStyles';

// Assets
import banner from '../assets/images/Banner.png';
// Styles
import '../assets/styles/pages/mainPage.css';
import Footer from '../components/Layout/Global/Footer';
import config from '../Config';

const { BACKEND_URL } = config;

const API_URL = `${BACKEND_URL}/api/no_session`;

const App = () => {
    const [images, setImages] = useState('');
    const [imgInfo, setImgInfo] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const [infoUrl, setInfoUrl] = useState('');
    const [scanning, setScanning] = useState(false);

    const getVisibleDefects = (imgInfo) => {
        if (!imgInfo.defects || imgInfo.defects.length === 0) {
            return []
        } else {
            return imgInfo.defects.map(defect => [defect.id, true])
        }
        
    }

    useEffect(() => {
        const setSession = async () => {
            try {
                await axios.get(`${API_URL}/set_session`, { withCredentials: true });
            } catch (setSessionError) {
                console.error('Error setting session:', setSessionError);
            }
        };
    
        setSession();
    
        const handleLogout = async () => {
            try {
                await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
                console.log("Logout exitoso");
            } catch (error) {
                console.error("Error durante el logout:", error);
            }
        };
    
        const handleBeforeUnload = (event) => {
            const logoutUrl = `${API_URL}/logout`;
            navigator.sendBeacon(logoutUrl); // Enviar sin esperar respuesta
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleFilesSelect = async (selectedFiles) => {
        try {
        setUploading(true);

        await new Promise(resolve => setTimeout(resolve, 2000));

        const formData = new FormData();
        const firstFile = Array.from(selectedFiles)[0];
        if (firstFile) {
            formData.append('file', firstFile);
}

        await axios.post(`${API_URL}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true,
        });

            fetchImages(); // Fetch images from Modules after upload
            setUploading(false);
        } catch (error) {
        console.error('Error uploading files:', error);
            setErrorMessage('Error uploading files. Please try again.');
            setUploading(false);
        }
    };

    const fetchNewInfo = async () => {
        const module_info = await axios.get(infoUrl, {withCredentials:true})
        if(!('defects' in (module_info?.data || {}))){
            module_info.data.defects = []
            setImgInfo(module_info.data)
        }else{
            setImgInfo(module_info.data)
        }
    }

    const fetchImages = async () => {
        try {
            const response = await axios.get(`${API_URL}/Modules`, { withCredentials: true });
            const imageUrl = `api/no_session/${response.data.url}?t=${new Date().getTime()}`; // Agrega un timestamp a la URL
            setImages(imageUrl);
            setInfoUrl(`${API_URL}/${response.data.info}`)
    
            const module_info = await axios.get(`${API_URL}/${response.data.info}`, { withCredentials: true });
            if (!('defects' in (module_info?.data || {}))) {
                module_info.data.defects = [];
            }
            setImgInfo(module_info.data);
        } catch (error) {
            console.error('Error fetching images:', error);
            setErrorMessage('Error fetching images. Please try again.');
        }
    };

    const handleFindDefects = async () => {
        try {
            setScanning(true);
            await new Promise(resolve => setTimeout(resolve, 2000));

            await axios.post(`${API_URL}/find_defects`, {}, {withCredentials: true});

            setScanning(false);
            fetchNewInfo(); // Fetch images from Printed_modules after analysis
        } catch (error) {
            console.error('Error finding defects:', error);
            setErrorMessage('Error finding defects. Please try again.');
            setScanning(false);
        }
    };

    return (
        <div className="page-container">
            <div className="content">
                <div className="App">
                    <RandomTextEffect text="Electroluminescence" />
                    {images.length > 0 ? (
                        <div className="images-container" >
                            <div className="button-column">
                                <div className={`vertical-slide ${uploading ? 'uploading-animation' : 'aa'}`}>
                                    <p sx={{ marginTop: '15%' }}></p>
                                    <InputFileUpload setFiles={handleFilesSelect} />
                                </div>

                                <div className='spawn-analyze'>
                                    <Typography color="white" sx={{ fontSize: '120%', marginTop: '5%' }}>
                                        Find defects in the modules!
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        sx={{ fontSize: '120%',marginBottom: '5%' }}
                                        onClick={handleFindDefects}
                                    >
                                        Find defects
                                    </Button>
                                </div>
                            </div>

                            <div className='spawn-carrusel'>
                                <Box className='limited-container'>
                                    <div className={`carousel-slide ${scanning ? 'scan-effect active' : 'scan-effect'}`}>
                                        <ImageDisplay currentImage={`${BACKEND_URL}/${images}`} defectData={imgInfo.defects} hoveredDefectId={[]} visibleDefects={getVisibleDefects(imgInfo)} onDefectStyles={getDefectStyles}/>
                                    </div>
                                </Box>
                            </div>
                        </div>
                    ) : (
                        <div className="Body">
                            <div className={`vertical-slide ${uploading ? 'uploading-animation' : ''}`}>
                                <InputFileUpload setFiles={handleFilesSelect} />
                            </div>
                        </div>
                    )}
                    
                    
                    {errorMessage && (
                        <Typography variant="body1" color="error" sx={{ textAlign: 'center', marginTop: '20px' }}>
                            {errorMessage}
                        </Typography>
                    )}
                    
                    {uploading ? (
                        <Box sx={{ width: '50%', margin: 'auto', marginBottom:'10%'}}>
                            <LinearProgress color="primary" />
                        </Box>
                    ) : <Box sx={{marginTop: '4px', marginBottom:'10%'}}></Box>
                    }
                    <Box>
                        <ImageCarousel images={[banner]}></ImageCarousel>
                    </Box>
                    
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default App;
