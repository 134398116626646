// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Copyright (C) CELSOS. All rights reserved. */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
  
body {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom,  #000000, #656CF6);
}
    
  .project-header {
    padding: 10px;
    width: 100%;
    height: 5%; /* Por ejemplo, 10% de la altura de la pantalla */
    background-color: #24273a;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 1.2em;
    box-sizing: border-box;
    box-shadow: 0 4px 2px #161827;  
    border-bottom: 2px solid #161827;
  }

  .main-content {
    flex: 1 1; /* Ocupa el espacio restante */
    overflow-y: auto; /* Permite desplazamiento vertical */
  }
  
  .columns {
    display: flex; /* Utiliza Flexbox para columnas */
  }
  
  .column {
    flex: 1 1; /* Cada columna ocupa el mismo espacio */
    width:'50%';
    padding: 10px; /* Espaciado interno para columnas */
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/pages/project.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;EACE,SAAS;EACT,UAAU;EACV,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yDAAyD;AAC3D;;EAEE;IACE,aAAa;IACb,WAAW;IACX,UAAU,EAAE,iDAAiD;IAC7D,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,gBAAgB;IAChB,sBAAsB;IACtB,6BAA6B;IAC7B,gCAAgC;EAClC;;EAEA;IACE,SAAO,EAAE,8BAA8B;IACvC,gBAAgB,EAAE,oCAAoC;EACxD;;EAEA;IACE,aAAa,EAAE,kCAAkC;EACnD;;EAEA;IACE,SAAO,EAAE,wCAAwC;IACjD,WAAW;IACX,aAAa,EAAE,oCAAoC;EACrD","sourcesContent":["/* Copyright (C) CELSOS. All rights reserved. */\nhtml, body {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n  \nbody {\n  display: flex;\n  flex-direction: column;\n  background: linear-gradient(to bottom,  #000000, #656CF6);\n}\n    \n  .project-header {\n    padding: 10px;\n    width: 100%;\n    height: 5%; /* Por ejemplo, 10% de la altura de la pantalla */\n    background-color: #24273a;\n    color: #ffffff;\n    display: flex;\n    align-items: center;\n    justify-content: left;\n    font-size: 1.2em;\n    box-sizing: border-box;\n    box-shadow: 0 4px 2px #161827;  \n    border-bottom: 2px solid #161827;\n  }\n\n  .main-content {\n    flex: 1; /* Ocupa el espacio restante */\n    overflow-y: auto; /* Permite desplazamiento vertical */\n  }\n  \n  .columns {\n    display: flex; /* Utiliza Flexbox para columnas */\n  }\n  \n  .column {\n    flex: 1; /* Cada columna ocupa el mismo espacio */\n    width:'50%';\n    padding: 10px; /* Espaciado interno para columnas */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
