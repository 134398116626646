// Copyright (C) CELSOS. All rights reserved.
import React from 'react';

// External Libraries
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/Home" />;
  }

  return children;
};

export default PublicRoute;
