// Copyright (C) CELSOS. All rights reserved.
import Cookies from 'js-cookie';
import HttpService from './HTTPService'

import config from '../Config';

const { BACKEND_URL } = config;

const API_URL = `${BACKEND_URL}/api/projects`;

const create = async (name, desc, criteria, tech, rows, columns, height, width) => {
    try {
      // Crear los datos en formato "form-data"
      const formData = new FormData();
      formData.append('project_name', name);
      formData.append('desc', desc);
      formData.append('criteria', criteria);
      formData.append('tech', tech);
      formData.append('rows', rows);
      formData.append('columns', columns);
      formData.append('height', height);
      formData.append('width', width);
      const response = await HttpService.getAxiosClient().post(`${API_URL}/create`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response.data

    } catch (error) {
        console.error('Error during project creation', error.response.detail);
        throw error;
    }
};

const getProject = async(projectName) => {
    try {
        const token = Cookies.get('session_token');
        const formData = new FormData();
        formData.append('project_name', projectName);
        formData.append('token', token);
        const response = await HttpService.getAxiosClient().post(`${API_URL}/get_one`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        });
        return response;
    } catch (error) {
        console.error('Error fetching user projects', error);
        throw error;
    }
}

const getAllProjects = async () => {
    try {
        const response = await HttpService.getAxiosClient().post(`${API_URL}/get_all`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user projects', error.response.detail);
        throw error;
    }
};

const getModuleCount = async (id) => {
    try {
        
        const formData = new FormData();
        formData.append('project_id', id);
        const response = await HttpService.getAxiosClient().post(`${API_URL}/get_module_count`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching user projects', error.response.detail);
        throw error;
    }
}

const chageCriteria = (criteriaId, projectId) => {
    try {
        const formData = new FormData();
        formData.append('criteria_id', criteriaId)
        formData.append('project_id', projectId)
        const response = HttpService.getAxiosClient().post(`${API_URL}/change_criteria`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        });
        return response.data;
    } catch (error) {
        console.error('Error changing criteria');
    }
}

const uploadFiles = async (selectedFiles, projectId, onProgress) => {
  // Iteramos sobre los archivos para manejar el progreso de cada uno
    for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append('project_id', projectId);
        formData.append('files', file); // Usamos 'file' en singular para subir uno a la vez

        // Realizamos la solicitud de carga con seguimiento de progreso
        await HttpService.getAxiosClient().post(`${API_URL}/upload_images`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress(file, progress); // Llamamos al callback de progreso para actualizar el progreso del archivo
        },
        });
    }
    return true;
};

const getProjectResults = async (projectId) => {
    try {
        const formData = new FormData();
        formData.append('project_id', projectId);
        const response = await HttpService.getAxiosClient().post(`${API_URL}/get_results_data`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        });
        response.data.made_up_severity = [
            {id: 0, value: 4002, label: "No defect"},
            {id: 1, value: 166, label: "Minor"},
            {id: 2, value: 30, label: "Major"},
            {id: 3, value: 5, label: "Critical"},
        ]
        return response.data
        // return response.data;
    } catch (error) {
        console.error('Error changing criteria');
    }
};

const deleteProject = async (projectId) =>  {
    try {
        const formData = new FormData();
        formData.append('project_id', projectId);
        await HttpService.getAxiosClient().post(`${API_URL}/delete_project`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        });
    } catch (error) {
        console.error('Error deleting project criteria');
    }
}

const updateProject = async (projectId, projectName, desc, tech, cellNumber, cellSize,  criteria) => {
    try {
        const formData = new FormData();
        formData.append('project_id', projectId);
        formData.append('project_name', projectName);
        formData.append('desc', desc);
        formData.append('criteria', criteria);
        formData.append('tech', tech);
        formData.append('cell_number', cellNumber);
        formData.append('cell_size', cellSize);

        await HttpService.getAxiosClient().post(`${API_URL}/update_project`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        });
    } catch (error) {
        console.error('Error deleting project criteria');
    }

}

export default {
    create,
    getProject,
    getAllProjects,
    getModuleCount,
    chageCriteria,
    uploadFiles,
    getProjectResults,
    deleteProject,
    updateProject
};
