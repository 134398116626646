// Copyright (C) CELSOS. All rights reserved.
// External Libraries
import React from 'react';
import { Divider } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';

// Components
import HoverableIcon from '../../Wrappers/HoverableIcon';

// Styles
import '../../../assets/styles/components/footer.css';


const Footer = () => {

    const openWindow = (url) =>{
        window.open(url, '_blank')
    }

    return (
        <footer className="footer">
            <div className="footer-content">
                <p>© 2024-2025 Celsos PV. All rights reserved.</p>
                <ul className="footer-links">
                    <li><a href="https://www.celsospv.com/privacy-policy/" target='blank'>Privacy Policy</a></li>
                    <li><a href='https://www.celsospv.com/cookies-policy/' target='blank'>Cookie Policy</a></li>
                    <li><a href="/terms-of-service">Terms of Service</a></li>
                    <li><a href={"https://www.celsospv.com#contact"} target='blank'>Contact Us</a></li>
                </ul>
                <Divider sx={{ bgcolor: "#ffffff", m:'30px' }}/>
                <ul className="footer-links">
                    <li><HoverableIcon IconComponent={LinkedInIcon} hoverColor="#0077b5" onClick={() => openWindow('https://www.linkedin.com/company/celsos/posts/?feedView=all')} /></li>
                    <li><HoverableIcon IconComponent={XIcon} hoverColor="#5f5f5f" onClick={() => openWindow('https://x.com/?lang=en')} /></li>
                    <li><HoverableIcon IconComponent={InstagramIcon} hoverColor="#E1306C" onClick={() => openWindow('https://i.pinimg.com/originals/88/14/9b/88149b0400750578f4d07d9bc3fb0fee.gif')} /></li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
