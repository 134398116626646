// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useState, useEffect } from 'react';

// Material-UI Components
import { Box, IconButton, Tab, Tabs, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

// Components
import MultipleSelectChip from '../components/old_comp/MultipleSelectChip';
import SelectSmall from '../components/Feature/SelectSmall';

// Styles
import '../App.css';
import Footer from '../components/Layout/Global/Footer';

const NewCriteria = () => {
    return (

        <>
            <div>
                <Typography variant="h6" gutterBottom>Custom criteria selection comming soon </Typography>
                
            </div>
            <Footer />
        </>
    );

};
// const NewCriteria = () => {
//   const defectTypes = [
//     'BlackCorner', 'BlackSpot', 'BypassDiode', 'CrossCrack', 'Dirt', 'Dot',
//     'EdgeWafer', 'FalseSoldering', 'Finger', 'GridLineShadow', 'ManufacturingPrints',
//     'MassiveCracks', 'Scratch', 'TypeACrack', 'TypeBCCrack', 'TypeDCrack', 'VCrack'
//   ];
//   const defectMetrics = [
//     'length', 'area', 'height', 'width', 'vertical_penetration',
//     'full_width', 'grey_value', 'not_allowed'
//   ];
//   const severities = ['No defect', 'Minor', 'Major', 'Critical'];
//   const severityIds = [0, 1, 2, 3]

//   const [formState, setFormState] = useState({
//     name: '',
//     defect: [],
//     metric: [],
//     severity: 0,
//     Q: '',
//     value: [],
//     selectedTab: 0,
//     q: ['', ''],
//     TA: ['', ''],
//   });

//   useEffect(() => {
//     const { selectedTab, q, TA, ...restState } = formState;
//     const additionalFields = selectedTab === 0 ? { q } : { TA };
//   }, [formState]);

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormState((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleSelectChange = (name, value) => {
//     setFormState((prevState) => {
//       if (name === 'metric') {
//         const newMetricValues = value.map((metric, index) => {
//           if (metric === 'not_allowed' || metric === 'full_width' || metric === 'vertical_penetration') {
//             return true;
//           }
//           return prevState.value[index] || ['', ''];
//         });
//         return { ...prevState, metric: value, value: newMetricValues };
//       }
//       return { ...prevState, [name]: value };
//     });
//   };

//   const handleMetricValueChange = (metric, index, value, subIndex) => {
//     setFormState((prevState) => {
//       const updatedMetricValues = [...prevState.value];
//       if (!updatedMetricValues[index]) {
//         updatedMetricValues[index] = ['', ''];
//       }
//       updatedMetricValues[index][subIndex] = value;
//       return { ...prevState, value: updatedMetricValues };
//     });
//   };

//   const handleDelete = () => {
//   };

//   const handleTabChange = (event, newValue) => {
//     setFormState((prevState) => ({
//       ...prevState,
//       selectedTab: newValue,
//     }));
//   };

//   const handleTabFieldChange = (tab, index, value) => {
//     setFormState((prevState) => {
//       const updatedTabFields = [...prevState[tab]];
//       updatedTabFields[index] = value;
//       return { ...prevState, [tab]: updatedTabFields };
//     });
//   };

//   const renderMetricFields = (metric, index) => {
//     const metricValue = formState.value[index] || ['', ''];

//     if (metric === 'length' || metric === 'area' || metric === 'height' || metric === 'width' || metric === 'grey_value') {
//       return (
//         <>
//           <Typography>{metric}</Typography>
//           <TextField
//             label='Min value'
//             value={metricValue[0] || ''}
//             onChange={(e) => handleMetricValueChange(metric, index, e.target.value, 0)}
//             margin="normal"
//             style={{ width: '10%', marginRight: '10px' }}
//             inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
//             sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily:'silkaregular' } }}
//             InputLabelProps={{
//               style: { color:'#656CF6', fontFamily:'silkaregular'},
//             }}
//           />
//           <TextField
//             label='Max value'
//             value={metricValue[1] !== 'inf' ? metricValue[1] : ''}
//             onChange={(e) => handleMetricValueChange(metric, index, e.target.value, 1)}
//             style={{ width: '10%' }}
//             margin="normal"
//             inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
//             sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily:'silkaregular' } }}
//             InputLabelProps={{
//               style: { color:'#656CF6', fontFamily:'silkaregular'},
//             }}
//           />
//         </>
//       );
//     }
//   };

//   return (

//     <>
//         <div>
            
//             <div style={{ padding: '20px', borderBottom: '1px solid #ccc', position: 'relative' }}>
//                 <IconButton
//                     aria-label="delete"
//                     onClick={handleDelete}
//                 >
//                     <DeleteIcon />
//                 </IconButton>
//                 <Typography variant="h6" gutterBottom>Reject Criterion {1}</Typography>
//                 <TextField
//                     label="Name"
//                     name="name"
//                     value={formState.name}
//                     onChange={handleChange}
//                     margin="normal"
//                     sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily:'silkaregular' } }}
//                     InputLabelProps={{
//                         style: { color:'#656CF6', fontFamily:'silkaregular'},
//                     }}
//                 />
//                 <MultipleSelectChip
//                     idPrefix={`form-${1}-defect`}
//                     options={defectTypes}
//                     fieldName="Defect"
//                     value={formState.defect}
//                     onChange={(value) => handleSelectChange('defect', value)}
//                 />
//                 <SelectSmall
//                     value={formState.severity}
//                     fieldName="Severity"
//                     onChange={(value) => handleSelectChange('severity', parseInt(value))}
//                     ids={severityIds}
//                     options={severities}
//                 />
//                 <MultipleSelectChip
//                     idPrefix={`form-${1}-metric`}
//                     options={defectMetrics}
//                     fieldName="Metric"
//                     value={formState.metric}
//                     onChange={(value) => handleSelectChange('metric', value)}
//                 />
//                 {formState.metric.map((metric, index) => (
//                     <div key={metric}>
//                         {renderMetricFields(metric, index)}
//                     </div>
//                 ))}
//                 <Tabs value={formState.selectedTab} onChange={handleTabChange}>
//                 <Tab 
//                     label="q" 
//                     sx={{
//                         fontFamily: 'silkaregular',
//                         color: '#656CF6', // Color del texto del tab inactivo
//                         textTransform: 'none', // Evita la conversión a mayúsculas
//                         '&.Mui-selected': {
//                             backgroundColor: '#121233', // Color del texto del tab activo
//                         },
//                     }}
//                 />
//                 <Tab 
//                     label="TA" 
//                     sx={{
//                         fontFamily: 'silkaregular',
//                         color: '#656CF6', // Color del texto del tab inactivo
//                         textTransform: 'none', // Evita la conversión a mayúsculas
//                         '&.Mui-selected': {
//                             backgroundColor: '#121233', // Color del texto del tab activo
//                         },
//                     }}
//                 />
//                 </Tabs>
//                 {formState.selectedTab === 0 && (
//                 <Box>
//                     <TextField
//                         label="q min value"
//                         value={formState.q[0]}
//                         onChange={(e) => handleTabFieldChange('q', 0, e.target.value)}
//                         style={{ width: '40%', marginRight: '5%', marginLeft: '5%' }}
//                         margin="normal"
//                         inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
//                         sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily:'silkaregular' } }}
//                         InputLabelProps={{
//                             style: { color:'#656CF6', fontFamily:'silkaregular'},
//                         }}
//                     />
//                     <TextField
//                         label="q max value"
//                         value={formState.q[1]}
//                         onChange={(e) => handleTabFieldChange('q', 1, e.target.value)}
//                         style={{ width: '40%' }}
//                         margin="normal"
//                         inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
//                         sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily:'silkaregular' } }}
//                         InputLabelProps={{
//                             style: { color:'#656CF6', fontFamily:'silkaregular'},
//                         }}
//                     />
//                 </Box>
//                 )}
//                 {formState.selectedTab === 1 && (
//                 <Box>
//                     <TextField
//                         label="TA min value"
//                         value={formState.TA[0]}
//                         onChange={(e) => handleTabFieldChange('TA', 0, e.target.value)}
//                         style={{ width: '40%', marginRight: '5%', marginLeft: '5%' }}
//                         margin="normal"
//                         inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
//                         sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily:'silkaregular' } }}
//                         InputLabelProps={{
//                             style: { color:'#656CF6', fontFamily:'silkaregular'},
//                         }}
//                     />
//                     <TextField
//                         label="TA max value"
//                         value={formState.TA[1]}
//                         onChange={(e) => handleTabFieldChange('TA', 1, e.target.value)}
//                         style={{ width: '40%' }}
//                         margin="normal"
//                         inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
//                         sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily:'silkaregular' } }}
//                         InputLabelProps={{
//                             style: { color:'#656CF6', fontFamily:'silkaregular'},
//                         }}
//                     />
//                 </Box>
//                 )}
//                 <TextField
//                     label="Q"
//                     name="Q"
//                     value={formState.Q}
//                     onChange={handleChange}
//                     margin="normal"
//                     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
//                     sx={{ input: { color: '#656CF6', background: '#1b1b49', fontFamily:'silkaregular' } }}
//                     InputLabelProps={{
//                         style: { color:'#656CF6', fontFamily:'silkaregular'},
//                     }}
//                 />
//             </div>
//         </div>
//         <Footer />
//     </>
//   );
// };

export default NewCriteria;