// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React from 'react';

// Components
import SelectSmall from '../../Feature/SelectSmall';

const CriteriaSelect = ({ criteria, onChange, criteriaIds, criteriaNames }) => {
    return (
    <div className="bottom-section">
        <SelectSmall
            value={criteria}
            fieldName="Criteria"
            onChange={onChange}
            ids={criteriaIds}
            options={criteriaNames}
        >
            Criteria
        </SelectSmall>
    </div>);
};

export default CriteriaSelect;
