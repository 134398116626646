// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Copyright (C) CELSOS. All rights reserved. */  
.form-container {
    padding: 30px;
    row-gap: 20px; 
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.creation-form {
    background: #fafaff ;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 60%;
    border-radius: 8px;
    padding: 20px;
    border: 4px solid #a9adfe8d;
    row-gap: 20px; /* Spacing between rows */
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/pages/create.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;IACI,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,2BAA2B;IAC3B,aAAa,EAAE,yBAAyB;AAC5C","sourcesContent":["/* Copyright (C) CELSOS. All rights reserved. */  \n.form-container {\n    padding: 30px;\n    row-gap: 20px; \n    max-height: 90vh;\n    overflow-y: auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.creation-form {\n    background: #fafaff ;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    width: 60%;\n    border-radius: 8px;\n    padding: 20px;\n    border: 4px solid #a9adfe8d;\n    row-gap: 20px; /* Spacing between rows */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
