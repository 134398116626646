// Copyright (C) CELSOS. All rights reserved.
// External Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';

// Contexts
import { useProject } from '../../../contexts/ProjectContext';

// Components
import DoublePieChart from './Charts/DoublePieChart';
import NormalPieChart from './Charts/NormalPieChart';
import NormalBarChart from './Charts/NormalBarChart';
import SwitchButtons from '../../Feature/ChartSwitchButtons';

// Styles
import '../../../assets/styles/components/charts.css';
import SpreadDataGrid from './Charts/SpreadDataGrid';


const ProjectOverview = ({ projectId }) => {
    const { fetchProjectResults } = useProject();
    const [resultData, setResultData] = useState(null);
    const [currentChart, setCurrentChart] = useState('bar'); // Estado para alternar entre gráficos

    const colorPalettes = {
        blues:['#656cf6', '#474dc9', '#8389f7', '#a7abf2'],
        severity:['#656cf6', '#4cad66', '#ed9d34', '#e02258'],
        trafficLight:['#4cad66', '#ed9d34', '#e02258']
    }
    useEffect(() => {
        const getData = async () => {
        const data = await fetchProjectResults(projectId);
        setResultData(data);
        };
        getData();
    }, [projectId]);

    const toggleChart = (chartType) => {
        setCurrentChart(chartType); // Cambia entre 'pie' y 'bar'
    };

    return (
        <div className="all_content">
            <div className="charts_content">

                <div className="chart_background">
                <Box ml={3}>
                    <Typography variant="h5" mt={2}>
                        Defect Count
                    </Typography>
                    {resultData ? (
                        <DoublePieChart outerRadius={125} height={293} width={450} data={resultData} innerColors={colorPalettes.blues} outerColors={colorPalettes.trafficLight}/>
                    ) : (
                        <Typography>Loading data...</Typography>
                    )}
                    </Box>
                </div>

                <div className="chart_background">
                <Box ml={3}>
                    <Typography variant="h5" mt={2}>
                        Aura Chart
                    </Typography>
                    {resultData ? (
                        <NormalPieChart outerRadius={'90%'} height={293} width={450} data={resultData.module_severities} colors={colorPalettes.severity}/>
                    ) : (
                        <Typography>Loading data...</Typography>
                    )}
                </Box>
                </div>
                
            </div>
            
            <div className="main_chart">
                <Box ml={3}>
                    <Box sx={{ display: 'flex', flexDirection:'row'}}>
                        <Typography variant="h5" mt={2}>
                            Module severity status
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <SwitchButtons currentChart={currentChart} toggleChart={toggleChart}/>
                    </Box>
                    {resultData ? (
                        <>
                            {/* Botones para alternar entre gráficos */}
                            
                            {/* Renderizado condicional del gráfico */}
                            {
                                currentChart === 'bar' 
                                    ? <NormalBarChart height={650} width={700} data={resultData.module_severities} colors={colorPalettes.severity} />
                                    : currentChart === 'pie' 
                                    ? <NormalPieChart height={650} width={700} outerRadius={250} data={resultData.module_severities} colors={colorPalettes.severity} ml={12} />
                                    : currentChart === 'grid' 
                                    ? <SpreadDataGrid height={''} width={'auto'} data={resultData} colors={colorPalettes.severity} />
                                    : null
                            }
                        </>
                    ) : (
                        <Typography>Loading data...</Typography>
                    )}
                </Box>
            </div>


            <div className="info_left">
                <Typography variant="h5" ml={3} mt={2}>
                    Project insights
                </Typography>
                <Typography variant="h5" ml={3} mt={2.5}>
                    CELSOS Score: 8.6
                </Typography>
                <Box m={3} style={{ textAlign: "justify"}}>
                    <Typography color={'white'} mt={1.5}>
                        {`<This text is a placeholder>`}
                    </Typography>
                    <Typography color={'white'} mt={1.5}>
                        The inspection of the solar energy system revealed overall satisfactory performance, with the panels operating at optimal efficiency.
                    </Typography>
                    <Typography color={'white'} mt={1.5}>
                    However, minor issues were identified, such as dust accumulation on some panels and a slight drop in energy output during peak hours. Regular maintenance, including cleaning and system checks, is recommended to ensure sustained performance.
                    </Typography>
                    <Typography color={'white'} mt={1.5}>
                    The findings suggest that the system is well-designed and capable of meeting energy demands, but proactive measures can further enhance its longevity and output.
                    </Typography>
                </Box>
                
            </div>
        </div>
    );
};

export default ProjectOverview;
