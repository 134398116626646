// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { createContext, useContext, useState } from 'react';

// Components
import ProjectService from '../services/ProjectService';



const ProjectContext = createContext();

export const useProject = () => {
  return useContext(ProjectContext);
};

export const ProjectProvider = ({ children }) => {
    const [project, setProject] = useState(null);
    const [error, setError] = useState(null);

    const fetchAllProjects = async () => {
        const response = await ProjectService.getAllProjects()
        return response
    }
  
    const fetchProject = async (projectName) => {
            try {
                const response = await ProjectService.getProject(projectName) // Cambia API_URL por la URL real de tu API
                setProject(response.data);
                return response.data.id;
            } catch (err) {
                    if (err.response && err.response.status === 404) {
                        throw err; // Indicar que el proyecto no fue encontrado
                } else {
                    setError('Error fetching project');
                }
            }
        };

    const fetchModuleCount = async (id) => {
        try {
            const response = await ProjectService.getModuleCount(id) // Cambia API_URL por la URL real de tu API
            return response
        } catch (err) {
            if (err.response && err.response.status === 404) {
                throw err; // Indicar que el proyecto no fue encontrado
            } else {
                setError('Error fetching project');
            }
        }
    };

    const changeCriteria = (criteriaId, projectId) => {
        try {
            const response = ProjectService.chageCriteria(criteriaId, projectId);
        } catch (err) {
            if (err.response && err.response.status === 404) {
                throw err; // Indicar que el proyecto no fue encontrado
            } else {
                setError('Error changing criteria');
            }
        }
    }

    const uploadFiles = async (selectedFiles, projectId, onProgress) =>{
        try {
        const response = ProjectService.uploadFiles(selectedFiles, projectId, onProgress);
        return response;
        } catch (err) {
            setError('Error uploading images');
        }
    }

    const fetchProjectResults = async (projectId) => {
        try {
            const response = ProjectService.getProjectResults(projectId);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 404) {
                throw err; // Indicar que el proyecto no fue encontrado
            } else {
            setError('Error fetching project results');
        }
        }
    };

    const deleteProject = async (projectId) => {
        try {
            const response = ProjectService.deleteProject(projectId);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 404) {
                throw err; // Indicar que el proyecto no fue encontrado
            } else {
                setError('Error fetching project results');
            }
        }
    }

    const updateProject = async (projectId, projectName, desc, tech, rows, columns, height, width, criteria) => {
        try {
            const cellNumber = `${rows}x${columns}`
            const cell_size = `${height}x${width}`
            const response = ProjectService.updateProject(projectId, projectName, desc, tech, cellNumber, cell_size,  criteria);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 404) {
                throw err; // Indicar que el proyecto no fue encontrado
            } else {
                setError('Error fetching project results');
            }
        }
    }

    return (
        <ProjectContext.Provider value={{ project, fetchAllProjects, fetchProject, fetchModuleCount, changeCriteria, uploadFiles, fetchProjectResults, deleteProject, updateProject, error }}>
            {children}
        </ProjectContext.Provider>
    );
};