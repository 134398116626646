// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React, { useEffect, useRef, useState } from 'react';

// Components
import Defect from '../../UI/Defect';

const ImageDisplay = ({ currentImage, defectData, hoveredDefectId, visibleDefects, onDefectStyles, lens }) => {
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  const [zoomLevel, setZoomLevel] = useState(1); // Nivel de zoom
  const [scale, setScale] = useState({ scaleX: 1, scaleY: 1 }); // Escala inicial de la imagen
  const [transformOrigin, setTransformOrigin] = useState('center center'); // Origen del zoom
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Estado de carga de la imagen
  const [isDragging, setIsDragging] = useState(false); // Estado de arrastre
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  

  // Calcula la escala y posición de la imagen al cargarse o redimensionarse
  const updateScaleAndPosition = () => {
    if (imageRef.current) {
      const displayedWidth = imageRef.current.clientWidth;
      const displayedHeight = imageRef.current.clientHeight;
      const naturalWidth = imageRef.current.naturalWidth;
      const naturalHeight = imageRef.current.naturalHeight;

      setScale({
        scaleX: displayedWidth / naturalWidth,
        scaleY: displayedHeight / naturalHeight,
      });
    }
  };

  // Maneja el zoom al desplazar la rueda del ratón
  const handleWheel = (e) => {
    if (!imageRef.current || !containerRef.current || !containerRef.current.contains(e.target)) return;

    e.preventDefault();
    e.stopPropagation();

    const zoomChange = e.deltaY > 0 ? -0.1 : 0.1;
    const newZoomLevel = Math.min(Math.max(zoomLevel + zoomChange, 1), 5); // Limitar zoom entre 1x y 5x

    const imageRect = imageRef.current.getBoundingClientRect();

    // Posición relativa del mouse sobre la imagen
    const offsetX = (e.clientX - imageRect.left) / imageRect.width;
    const offsetY = (e.clientY - imageRect.top) / imageRect.height;

    // Calcular el nuevo desplazamiento para centrar el zoom donde está el mouse
    const newX = position.x - (offsetX - 0.5) * imageRect.width * (newZoomLevel - zoomLevel);
    const newY = position.y - (offsetY - 0.5) * imageRect.height * (newZoomLevel - zoomLevel);

    // Tamaño del contenedor
    const containerRect = containerRef.current.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;

    // Tamaño de la imagen escalada
    const imageWidth = imageRef.current.naturalWidth * newZoomLevel;
    const imageHeight = imageRef.current.naturalHeight * newZoomLevel;

    // Calcular límites
    const minX = Math.min(0, containerWidth - imageWidth);
    const maxX = 0;
    const minY = Math.min(0, containerHeight - imageHeight);
    const maxY = 0;

    // Aplicar límites
    setPosition({
        x: Math.min(Math.max(newX, minX), maxX),
        y: Math.min(Math.max(newY, minY), maxY),
    });

    setZoomLevel(newZoomLevel); // Actualizar el nivel de zoom
};


const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);

    // Guardar la posición inicial del arrastre
    setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
    });
};

const handleMouseMove = (e) => {
    if (!isDragging) return;

    const newX = e.clientX - dragStart.x;
    const newY = e.clientY - dragStart.y;

    setPosition({ x: newX, y: newY }); // Actualizar posición acumulativa
};

const handleMouseUp = () => {
    setIsDragging(false); // Finalizar arrastre
};

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
      return () => {
        container.removeEventListener('wheel', handleWheel);
      };
    }
  }, [zoomLevel]);

  // Ejecuta la actualización de escala cuando la imagen se carga o la ventana cambia de tamaño
  const handleImageLoad = () => {
    setIsImageLoaded(true);
    updateScaleAndPosition();
  };

  useEffect(() => {
    if (isImageLoaded) {
      window.addEventListener('resize', updateScaleAndPosition);
      return () => window.removeEventListener('resize', updateScaleAndPosition);
    }
  }, [isImageLoaded]);

  return (
    <div
      ref={containerRef}
      className="image-viewer"
      onWheel={handleWheel} // Maneja el zoom exclusivamente en este contenedor
      onMouseMove={handleMouseMove} // Maneja el movimiento del ratón
      onMouseUp={handleMouseUp} // Finaliza el arrastre al soltar el mouse
      onMouseLeave={handleMouseUp} // Finaliza el arrastre al salir del contenedor
      style={{
        overflow: 'hidden', // Forzar que solo este contenedor sea afectado
        cursor: isDragging ? 'grabbing' : 'grab', // Cambiar el cursor según el estado de arrastre
      }}
    >
      <div
        className="top-section"
        onMouseDown={handleMouseDown}
        style={{
            left: position.x, // Posición horizontal ajustada por el arrastre
            top: position.y,
            position: 'relative',
            display: 'inline-block',
            transform: `scale(${zoomLevel})`, // Aplica el zoom
            transformOrigin: transformOrigin, // Aplica el origen dinámico del zoom
        }}
      >
        <img
          ref={imageRef}
          src={currentImage}
          alt="Selected"
          onLoad={handleImageLoad}
        />
        {isImageLoaded &&
          defectData.map((defect, index) => {
            const { label, lightColor, darkColor, color } = onDefectStyles(defect.name, lens);

            // Ajusta las coordenadas del bbox según el nivel de zoom y escala
            const scaledX1 = (defect.print_bbox[0] * scale.scaleX) + 10;
            const scaledY1 = (defect.print_bbox[1] * scale.scaleY) + 10;
            const scaledX2 = (defect.print_bbox[2] * scale.scaleX) + 10;
            const scaledY2 = (defect.print_bbox[3] * scale.scaleY) + 10;

            const isHovered = hoveredDefectId === defect.id;

            return visibleDefects[defect.id] && (
              <Defect
                id={defect.id}
                key={index}
                x1={scaledX1}
                y1={scaledY1}
                x2={scaledX2}
                y2={scaledY2}
                textColor={darkColor}
                borderColor={lightColor}
                bgcolor={color}
                label={label}
                score={defect.score}
                border={isHovered ? 3 : 1}
                onClick={() => console.log(defect)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ImageDisplay;
