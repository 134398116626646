// Copyright (C) CELSOS. All rights reserved.
// External Libraries
import React, { useState } from 'react';
import { Button, Toolbar, Typography, Box, AppBar } from '@mui/material';

// Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Custom Components
import RoundedTypography from '../../../UI/RoundedTypography';
import SessionForm from '../../../Feature/SessionForm';

// Contexts
import { useAuth } from '../../../../contexts/AuthContext';


const SessionlessHeader = () => {

  const kc = useAuth();

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
        <div style={{ backgroundColor: '#656aff', height: '5px', width: '100%' }} />
            <AppBar position="static" color="black" sx={{ height: '100px' }}>
                <Toolbar sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    {/* Webpage Title */}
                    <img
                        onClick={() => window.location.reload()}
                        src="/Logotipo-CELSOS-21.png"
                        alt="My Logo"
                        style={{ height: 54, cursor: "pointer"}}
                    />
                    <RoundedTypography padding={-1} borderRadius="10px">
                        CellGuard AI
                    </RoundedTypography>

                    {/* Space to push items to the right */}
                    <Box sx={{ flexGrow: 1 }} />

                    <div className='header-links'>
                        <a
                            href="https://www.celsospv.com/"
                            target='_blank'
                            sx={{ textTransform: 'capitalize', mr: 10}}
                        >
                            <Typography variant='h5'>
                                Visit our website
                            </Typography>
                        </a>
                    </div>
                <Button
                    onClick={() => { kc.login() }}
                    sx={{ backgroundColor: '#656cf6', width: '12%' }}
                >
                    <Typography
                        variant='h5'
                        sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                        color="white"
                    >
                        Log in
                    </Typography>
                    <AccountCircleIcon
                        sx={{ marginLeft:'5%', fontSize: 70, color: '#ffffff' }} // Tamaño del ícono
                    />
                </Button>
            </Toolbar>
        </AppBar>
        <SessionForm open={openDialog} handleClose={handleCloseDialog} />
    </React.Fragment>
  );
}

export default SessionlessHeader;