// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React from 'react';

// External Libraries
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Icons
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import DeleteIcon from '@mui/icons-material/Delete';

const BreadcrumbHeader = ({ projectName, img, startAnalysis, uploadFiles, deleteFiles, selectedRows }) => {
    const navigate = useNavigate();

    return(
        <div className="image-header">
        
            <Typography onClick={() => navigate('/Home')} sx={{ cursor: 'pointer', fontWeight:'bold' }}>
                My Projects
            </Typography>
            <a style={{ padding:5 }}> / </a>
            <Typography onClick={() => navigate(`/Projects/${projectName}`)} sx={{ cursor: 'pointer', fontWeight:'bold' }}>
                {projectName}
            </Typography>
            <a style={{ padding:5 }}> / </a>
            <Typography sx={{ fontWeight:'bold' }}> {img}</Typography>
            
            <Box sx={{ flexGrow: 1 }} />

            <Button 
            variant='contained' 
            color='error' 
            sx={{ mr:1 }} 
            onClick={deleteFiles}
            disabled={ selectedRows && selectedRows.length === 0}
             >
                <DeleteIcon /> 
            </Button>

            {uploadFiles ? 
                <Button variant='contained' sx={{ mr:1 }} onClick={uploadFiles}>
                    <FileUploadRoundedIcon sx={{ mr: 1, ml: -1 }}/> 
                    <Typography sx={{fontWeight:'bold'}}> Import </Typography>
                </Button>
            :
                <></>
            }

            <Button variant='contained' sx={{ mr:1 }} onClick={startAnalysis}>
                <AutoAwesomeRoundedIcon sx={{ mr: 1, ml: -1 }}/> 
                <Typography sx={{fontWeight:'bold'}}> Find Defects </Typography>
            </Button>
        
        </div>
    );
};

export default BreadcrumbHeader;
