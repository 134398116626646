// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Copyright (C) CELSOS. All rights reserved. */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Ajusta columnas automáticamente */
    grid-gap: 35px;  /* Espacio entre los elementos */
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
}
  
  /* Estilos de los elementos dentro de la cuadrícula */
.grid-item {
    height: 190px;
    border: 3px solid #656cf6;  /* Borde fino */
    text-align: center;  /* Centrar el contenido */
    font-size: 1.2rem;
    border-radius: 8px;  /* Bordes redondeados */
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/pages/home.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;IACI,aAAa;IACb,4DAA4D,EAAE,oCAAoC;IAClG,cAAc,GAAG,gCAAgC;IACjD,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;EAEE,qDAAqD;AACvD;IACI,aAAa;IACb,yBAAyB,GAAG,eAAe;IAC3C,kBAAkB,GAAG,yBAAyB;IAC9C,iBAAiB;IACjB,kBAAkB,GAAG,uBAAuB;IAC5C,gBAAgB;AACpB","sourcesContent":["/* Copyright (C) CELSOS. All rights reserved. */\n.grid-container {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Ajusta columnas automáticamente */\n    grid-gap: 35px;  /* Espacio entre los elementos */\n    margin-left: 40px;\n    margin-right: 40px;\n    margin-top: 40px;\n}\n  \n  /* Estilos de los elementos dentro de la cuadrícula */\n.grid-item {\n    height: 190px;\n    border: 3px solid #656cf6;  /* Borde fino */\n    text-align: center;  /* Centrar el contenido */\n    font-size: 1.2rem;\n    border-radius: 8px;  /* Bordes redondeados */\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
