// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React from 'react';

// External Libraries
import { Tooltip, IconButton, Avatar, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import RuleIcon from '@mui/icons-material/Rule';
import Logout from '@mui/icons-material/Logout';


const AccountMenuButton = ({ kc }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Tooltip title="Account settings">
                <IconButton
                onClick={handleClick}
                size="medium"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{ background: "#656cf6", width: 64, height: 64 }}>IC</Avatar>
                </IconButton>
            </Tooltip>

            <Menu
                id="account-menu"
                MenuListProps={{
                    'aria-labelledby': 'account-menu-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => navigate('/profile')}>
                    <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                
                <MenuItem onClick={() => navigate('/home')}>
                    <ListItemIcon>
                        <PhotoAlbumIcon fontSize="small" />
                    </ListItemIcon>
                    My projects
                </MenuItem>

                <MenuItem onClick={() => navigate('/criteria')}>
                    <ListItemIcon>
                        <RuleIcon fontSize="small" />
                    </ListItemIcon>
                    My criteria
                </MenuItem>

                {/* <MenuItem onClick={() => navigate('/accountSettings')}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem> */}

                <MenuItem onClick={() => { navigate("/"); kc.logout(); }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

export default AccountMenuButton;
