// Copyright (C) CELSOS. All rights reserved.

// Core Libraries
import React, { createContext, useContext, useState } from 'react';

// Components
import ModuleService from '../services/ModuleService';



const ModuleContext = createContext();

export const useModule = () => {
    return useContext(ModuleContext);
};

export const ModuleProvider = ({ children }) => {
    const [module, setModule] = useState(null);
    const [error, setError] = useState(null);

    const fetchModule = async ( projectName, moduleId ) => {
        const response = await ModuleService.getModule(projectName, moduleId)
        return response
    }

    const fetchModuleThumbnail = async ( projectName, moduleId ) => {
        const response = await ModuleService.getModuleThumbnail(projectName, moduleId)
        return response
    }

    const fetchAllModules = async ( projectId ) => {
        const response = await ModuleService.getAllModules( projectId )
        return response
    }

    const deleteSetModules = async ( projectId, moduleList ) => {
        const response = await ModuleService.deleteSetModules( projectId, moduleList )
        return response
    }

    const analyzeSingleModule = async (projectId, moduleId) => {
        const response = await ModuleService.analyzeSingleModule( projectId, moduleId )
        return response
    }

    const analyzeSetModules = async (projectId, moduleList) =>{
        try {
            const response = ModuleService.analyzeSetModules(projectId, moduleList);
            return response;
          } catch (err) {
            if (err.response && err.response.status === 404) {
              throw err; // Indicar que el proyecto no fue encontrado
            } else {
            setError('Error fetching project results');
            }
          }
    }

    return (
        <ModuleContext.Provider value={{ module, fetchAllModules, fetchModule, fetchModuleThumbnail, deleteSetModules, analyzeSingleModule, analyzeSetModules, error }}>
            {children}
        </ModuleContext.Provider>
    );
};