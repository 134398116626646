// Copyright (C) CELSOS. All rights reserved.
import HttpService from './HTTPService'

import config from '../Config';

const { BACKEND_URL } = config;

const API_URL = `${BACKEND_URL}/api`;

const create = async () => {
    try {
      // Crear los datos en formato "form-data"
      const response = await HttpService.getAxiosClient().post(`${API_URL}/create`);
      return response.data

    } catch (error) {
        console.error('Error during project creation', error.response.detail);
        throw error;
    }
};

const getPinned = async () => {
    try {
        const response = await HttpService.getAxiosClient().post(`${API_URL}/users/get_pinned`);
        return response.data;
        
    } catch (error) {
        console.error('Error fetching user pinned project list');
    }
};

const setPinned = async (pinnedList) => {
    try {
        const formData = new FormData();
        formData.append('pinned_list', JSON.stringify(pinnedList));
        const response = await HttpService.getAxiosClient().post(`${API_URL}/users/set_pinned`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching user pinned project list');
    }
};
  

export default {
    create,
    getPinned,
    setPinned
};
