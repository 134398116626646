// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';

// Material-UI Components
import { Tooltip, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


// Styles
import '../../assets/styles/pages/create.css';

const InfoTooltip = ({ label }) => {

    return (
        <Tooltip title={label} arrow>
            <IconButton size="small">
                <HelpOutlineIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

export default InfoTooltip;

