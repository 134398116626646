// Copyright (C) CELSOS. All rights reserved.
import React from 'react';

// Material-UI Components
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectSmall = ({ value, onChange, options, ids, fieldName }) => {
  return (
    <FormControl sx={{ m: 1, width: '500px' }} size="medium">
      <InputLabel 
        id={`select-small-${fieldName}`}
        sx={{
          color: '#656CF6',
          '&.Mui-focused': {
            color: '#656CF6',
          },
        }}
      >
        {fieldName}
      </InputLabel>
      <Select
        labelId={`select-small-${fieldName}`}
        id={`select-small-${fieldName}`}
        value={value} // Debe ser el ID seleccionado
        label={fieldName}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: '#1b1b49',
              color: '#656CF6',
            },
          },
          sx: {
            "&& .Mui-selected": {
              color: "#656CF6",
              background: "#1b1b49",
            },
          },
        }}
        sx={{
          fontFamily: 'silkaregular',
          backgroundColor: "#1b1b49",
          color: "#656CF6",
          "&:hover": {
            backgroundColor: "#29295c",
          },
          "&.Mui-focused": {
            backgroundColor: "#29295c",
          },
          "& .MuiSelect-icon": {
            color: "#656CF6",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem 
            key={ids[index]} // Usar el ID como clave
            value={ids[index]} // El valor será el ID correspondiente
            sx={{
              fontFamily: 'silkaregular',
              backgroundColor: '#1b1b49',
              color: '#656CF6',
              "&.Mui-selected": {
                backgroundColor: "#252567",
                color: "#656CF6",
              },
              "&:hover": {
                backgroundColor: "#29295c",
              },
            }}
          >
            {option} {/* Mostrar el nombre */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectSmall;
