// Copyright (C) CELSOS. All rights reserved.
// Material-UI Components
import { Typography, Box } from '@mui/material';
import { PieChart } from '@mui/x-charts';

const DoublePieChart = ({ height, width, outerRadius, data, innerColors, outerColors }) => {
  return (
    <Box 
    sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'left', // Centra la leyenda en el div
        overflow: 'auto', // Permite que los elementos se ajusten sin recortes
      }}>
        <PieChart
        series={[
            {
            data: data.severities.map((item, index) => ({
                id: `severity,${index}`,
                value: item.value,
                label: item.label,
                color: outerColors[index % 4],
            })),
            innerRadius: outerRadius - 15,
            outerRadius: outerRadius,
            },
            
            {
            data: data.all.map((item, index) => ({
                id: `all,${index}`,
                value: item.value,
                label: item.label,
                color: innerColors[index % 4],
            })),
            cornerRadius: 3,
            highlightScope: { fade: 'series', highlight: 'item' },
            innerRadius: 0,
            outerRadius: outerRadius - 20,
            },
        ]}
        width={width}
        height={height}
        slotProps={{
            tooltip: {
                labelFormatter: (value) => `${value} instances found`, // Formato de tooltip
            },
            legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            padding: -40,
            labelStyle: {
                fontSize: 14,
                fill: 'white',
            },
            
            },
            
        }}
        />
    </Box>
  );
};

export default DoublePieChart;