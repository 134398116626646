// Copyright (C) CELSOS. All rights reserved.
import { Box, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';


const SpreadDataGrid = ({ data, height, width }) => {
    const exportToCSV = (data) => {
        // Convert the object to a CSV string
        const headers = Object.keys(data[0]).join(",") + "\n"; // Create the CSV headers
        const rows = data.map(row => Object.values(row).join(",")).join("\n"); // Map rows to CSV

        const csvContent = headers + rows;

        // Create a Blob object with the CSV content
        const blob = new Blob([csvContent], { type: "text/csv" });

        // Create a link element to download the CSV file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = 'data.csv';

        // Append link to the body, trigger the click, and remove the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const severityColumns = [
            {
                field: 'label',
                headerName: 'Severity',
                width: 150,
            },
            {
                field: 'value',
                headerName: '# Modules',
                type: 'number',
                width: 110,
            },
      ];

    const defectColumns = [
        {
            field: 'label',
            headerName: 'Defect Name',
            width: 150,
        },
        {
            field: 'value',
            headerName: '# Defects',
            type: 'number',
            width: 110,
        },
    ];
      
        return (
            <Box
                sx={{
                    background: 'white',
                    border: '3px solid white',
                    borderRadius: 3,
                    marginTop: 2,
                    display: 'flex', // Asegura que sea un contenedor flex
                    flexDirection: 'row', // Los hijos estarán en fila
                    alignItems: 'flex-start', // Alinea los hijos al inicio verticalmente
                    gap: 5, // Espaciado entre los elementos
                    ml:24
                }}
            >
                <Box sx={{ flex: 1}}> {/* Define el ancho relativo del primer elemento */}
                    <Typography color={'primary'} ml={7} mb={1} mt={1}> Grouped by severity </Typography>
                    <DataGrid
                        rows={data.module_severities}
                        columns={severityColumns}
                        pageSizeOptions={[100]}
                        sx={{ height: height, width: width, ml: 1}}
                    />
                    <Button sx={{ ml:10, mb:1, mt:10}} variant='contained' onClick={() => exportToCSV(data.module_severities)}> Export to CSV</Button>
                </Box>

                <Box sx={{ flex: 1 }}> {/* Define el ancho relativo del segundo elemento */}
                    <Typography color={'primary'} ml={5} mb={1} mt={1}> Grouped by defect type </Typography>
                    <DataGrid
                        rows={data.all}
                        columns={defectColumns}
                        pageSizeOptions={[100]}
                        sx={{ height: height, width: width, mr: 1 }}
                    />
                    <Button sx={{ ml:8, mb:1, mt:1}} variant='contained' onClick={() => exportToCSV(data.all)}> Export to CSV</Button>
                </Box>
            </Box>
        );
  };
  
  export default SpreadDataGrid;