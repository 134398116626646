// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

// Styles
import '../assets/styles/pages/project.css';

// Material-UI Components
import { Typography } from '@mui/material';

// Components
import Visualizer from './Visualizer';
import Project from './Project';

const ProjectView = () => {

  const { projectName } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const img = queryParams.get('img');

  if (!projectName) return <Typography>Project not found</Typography>;

  return (
    <div>
        <div>
        {img ? (
            <Visualizer projectName={projectName} imgId={img} />
        ) : (
            <Project />
        )}
        </div>
    </div>
  );
}

export default ProjectView;
