// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React, { useState } from 'react';
// Material-UI Components
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import CustomTextField from '../../UI/CustomTextField';
import SelectSmall from '../../Feature/SelectSmall';
import { useNavigate } from 'react-router-dom';
import { useProject } from '../../../contexts/ProjectContext';
import DimensionsTextBox from '../../Feature/DimensionsTextBox';
import InfoTooltip from '../../Feature/InfoTooltip';


const ProjectOverview = ({project, availableCriteria}) => {

    
    const { updateProject } = useProject();
    const [projectName, setProjectName] = useState(project.name);
    const [desc, setDesc] = useState(project.desc);
    const [tech, setTech] = useState(project.tech_type);
    const [error, setError] = useState('');
    const [rows, setRows] = useState((project.cell_number).split('x')[0]);
    const [columns, setColumns] = useState((project.cell_number).split('x')[1]);
    const [height, setHeight] = useState((project.cell_size).split('x')[0]);
    const [width, setWidth] = useState((project.cell_size).split('x')[1]);
    const [criteria, setCriteria] = useState(project.criteria);

    const navigate = useNavigate();

    const handleChange = (e) => {
        setCriteria(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // comprobaciones
        try {
            setError('');
            await updateProject(project.id, projectName, desc, tech, rows, columns, height, width, criteria)
            navigate(`/Projects/${projectName}`)
            window.location.reload()
        } catch (err) {
            // Manejo de errores, si falla el registro
            if (typeof err.response.data.detail == "string"){
                setError(err.response.data.detail);
            }else{
                setError("Error during project creation, try again later");
            }
        }
    };

    
    return(
        <div className='form-container' style={{ marginTop: -50}}>
    
            <div className="creation-form">
                {error && <p style={{ color: 'red' }}>
                    {typeof error === 'object' ?
                        'There was an unexpected error, please make sure all fields are filled in'
                        : 
                        error
                    }
                    </p>
                }
                
                <Typography variant="h5" color="primary"> Project info </Typography>
                <form onSubmit={handleSubmit}>
                    <Divider sx={{ bgcolor: "black"}}/>
                    <div width="140%" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CustomTextField 
                                label="Project name" 
                                required 
                                value={projectName} 
                                onChange={(e) => setProjectName(e.target.value)}
                                inputProps={{ maxLength: 30 }} 
                            />
                            <InfoTooltip 
                            label={"The name you choose for your project will serve as \
                                    its unique identifier. It must be distinctive and \
                                    not already in use to ensure clarity and originality."}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CustomTextField
                                id="outlined-multiline-static"
                                label="Description"
                                multiline
                                rows={4}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                                inputProps={{ maxLength: 256 }} 
                            />
                            <InfoTooltip 
                            label={"The description of your project should clearly outline \
                                    its purpose, goals, and key features. It must be detailed \
                                    enough to provide a comprehensive understanding, yet concise, \
                                    to capture attention and convey its uniqueness effectively."
                                   }/>
                        </div>
                    <Typography variant="h5" color="primary"> Modules info </Typography>
                    
                    <Divider sx={{ bgcolor: "black" }}/>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <FormControl required sx={{ m: 1, minWidth: '85%' }}>
                                <InputLabel id="demo-simple-select-helper-label" sx={{ color: '#656CF6', fontFamily: 'silkaregular' }}>Tech type</InputLabel>
                                <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={tech}
                                
                                label="tech"
                                onChange={(e) => setTech(e.target.value)}
                                >
                                    <MenuItem value={'Monocrystalline'}>Monocrystalline</MenuItem>
                                    <MenuItem value={'Polycrystalline'}>Polycrystalline</MenuItem>
                                </Select>
                            </FormControl>
                            <InfoTooltip label="Select the appropriate technology type for the modules in your project"/>
                        </div>
                        <DimensionsTextBox 
                        label={"Rows represent the horizontal arrangement of solar cells, \
                                while columns indicate the vertical alignment within each \
                                row when the module is placed horizontally."
                              } 
                        setSmall={setRows} 
                        setBig={setColumns} 
                        rows={rows} 
                        columns={columns}
                        smallLabel={"Rows"}
                        bigLabel={"Columns"}
                        unit={"cells"} 
                        />
                        
                        <Typography variant="h5" color="primary">Cell info</Typography>
                        <DimensionsTextBox 
                        label={"The width refers to the horizontal dimension of the cell,\
                                and the height refers to the vertical dimension when the \
                                module is positioned horizontally."} 
                        setSmall={setWidth} 
                        setBig={setHeight} 
                        rows={width} 
                        columns={height}
                        smallLabel={"Width"}
                        bigLabel={"Height"}    
                        unit={"mm"}        
                        />
                        <Button
                            variant="contained"
                            tabIndex={-1}
                            sx={{ fontSize: '120%' }}
                            type="submit"
                        >
                            Save Changes
                        </Button>
                
                </form>
            </div>
        </div>
    );
};

export default ProjectOverview;
