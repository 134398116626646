// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';
// Material-UI Components
import { Box, Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const MultipleSelectChip = ({ idPrefix, options, fieldName, value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl  style={{ width:'400px'}} margin="normal">
      <InputLabel 
        id={`${idPrefix}-${fieldName}-label`}
        sx={{
          fontFamily:'silkaregular',
          color: value.length ? '#656CF6' : '#656CF6', // Color del label (cambia si hay algo seleccionado o no)
          '&.Mui-focused': {
            color: '#656CF6', // Color cuando el Select está enfocado
          },
        }}
      >
        {fieldName}
      </InputLabel>
      <Select
        labelId={`${idPrefix}-${fieldName}-label`}
        id={`${idPrefix}-${fieldName}`}
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip 
                key={value} 
                label={value} 
                sx={{
                  fontFamily:'silkaregular',
                  backgroundColor: '#252567', // Color de fondo del chip
                  color: '#656CF6',           // Color del texto del chip
                }}
              />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: '#1b1b49', // Fondo del menú desplegable
              color: '#656CF6',           // Color del texto del menú
            },
          },
          sx: {
            "&& .Mui-selected": {
              color: "#656CF6",        // Color del texto de la opción seleccionada
              background: "#121233",   // Color de fondo de la opción seleccionada
            },
          },
        }}
        sx={{
          "& .MuiSelect-select": {
            backgroundColor: "#1b1b49", // Fondo del área del Select cuando está cerrado
            color: "#656CF6",           // Color del texto en el área del Select
          },
          "&:hover .MuiSelect-select": {
            backgroundColor: "#121233", // Fondo al pasar el mouse sobre el Select cerrado
          },
          "&.Mui-focused .MuiSelect-select": {
            backgroundColor: "#121233", // Fondo cuando el Select está enfocado y abierto
          },
          "& .MuiSelect-icon": {
            color: "#656CF6",          // Color de la flecha del desplegable
          },
        }}
      >
        {options.map((option) => (
          <MenuItem 
            key={option} 
            value={option}
            sx={{
              fontFamily:'silkaregular',
              "&.Mui-selected": {
                backgroundColor: "#1b1b49",  // Color de fondo de la opción seleccionada
                color: "#656CF6",            // Color del texto de la opción seleccionada
              },
              "&:hover": {
                backgroundColor: "#29295c",  // Color de fondo al pasar el mouse por encima
              },
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectChip;
