// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useEffect, useState } from 'react';

// Material-UI Components
import { Tooltip, Typography } from '@mui/material';
const Defect = ({ id, x1, y1, x2, y2, label, score, onClick, bgcolor, borderColor, textColor, border }) => {
    const [reason, setReason] = useState()
    useEffect(() => {
      const defectReason = () => {
        switch (label) {
            case "Dot":
              return 'Grey area exceeds the allowed';
            case "Type A Crack":
              return 'Crack length exceeds the allowed';
            case "False Soldering":
              return 'Added total area of the defects exceeds the allowed';
            case "Cross Crack":
              return 'Cross crack is not allowed';
            case "Massive Cracks":
              return 'Massive cracks are not allowed';
            case "Type D Crack":
              return 'Type D Cracks are not allowed';
            case "Type BC Crack":
              return 'Type BC Cracks are not allowed';
            case "Bypass Diode":
              return 'gg';
            case "Manufacturing Prints":
              return 'aaaaa';
            case "Edge Wafer":
              return 'Edge Waffle';
            case "Finger":
              return 'Star finger';
            default:
              return 'This type of defect is not allowed';
          }
        };
      setReason(defectReason())
    }, [])
    return (
        <Tooltip 
            arrow
            slotProps={{
                tooltip: {
                sx: {
                    backgroundColor: `${bgcolor}CC`,
                    border: `2px solid ${borderColor}`,
                },
                },
                arrow:{
                    sx: {
                        color: `${bgcolor}CC`,
                    },
                },
            }}
            title={
                <div >
                <Typography className="label" color={textColor || 'primary'} style={{ fontWeight:'bold'}}>
                    {label}
                </Typography>
                <Typography className="label" color={textColor || 'primary'} style={{ fontWeight:'bold', fontSize:'12px'}}>
                    Score: {(score * 100).toFixed(2)}%
                </Typography>
                <Typography className="label" color={textColor || 'primary'} style={{ fontWeight:'bold'}}>
                  {reason}
                </Typography>
            </div>
            }
        >
            <div
            id={id}
            className="defect"
            style={{
                position: 'absolute',
                top: `${y1}px`,
                left: `${x1}px`,
                width: `${x2 - x1}px`,
                height: `${y2 - y1}px`,
                border: `${border}px solid ${borderColor}`,
                borderRadius: 2,
                backgroundColor: `${bgcolor}2f`,
                cursor: 'pointer',
            }}
            onClick={onClick}
            >
            </div>
        </Tooltip>
    );
};

export default Defect;
