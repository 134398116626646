// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

// Styles
import '../assets/styles/pages/project.css';

// Contexts
import { useProject } from '../contexts/ProjectContext';
import { useModule } from '../contexts/ModuleContext';

// Material-UI Components
import { Box } from '@mui/material';

// Components
import BreadcrumbHeader from '../components/Layout/Global/BreadcrumbHeader';
import ProjectOverview from '../components/Layout/Project/ProjectOverview';
import ProjectSettings from '../components/Layout/Project/ProjectSettings';
import ProjectTable from '../components/Layout/Project/ProjectTable';
import ImportModal from '../components/Layout/Project/ImportModal';
import ProjectTabs from '../components/Layout/Project/ProjectTabs';
import LoadingScreen from '../components/Layout/Global/LoadingScreen';
import DeleteModal from '../components/Feature/DeleteModal';
import InferenceModal from '../components/Feature/InferenceModal';
import { useCriteria } from '../contexts/CriteriaContext';


const Project = () => {
    const location = useLocation();
    const { fetchCriteria } = useCriteria();
    const { projectName } = useParams();
    const navigate = useNavigate();
    const { project, fetchProject, error } = useProject();
    const { fetchAllModules, fetchModule, fetchModuleThumbnail, analyzeSetModules, deleteSetModules } = useModule();
    const [tabIndex, setTabIndex] = useState(location.state?.tabIndex || 0);
    const [rows, setRows] = React.useState([]);
    const [projectId, setProjectId] = useState();
    const [openImport, setOpenImport] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openInference, setOpenInference] = useState(false);
    const [isAppReady, setIsAppReady] = useState(false); // Nuevo estado para controlar la carga inicial
    const [selectedRows, setSelectedRows] = useState([]);
    const [analyzedRows, setAnalyzedRows] = useState([]);
    const [allRows, setAllRows] = useState([])
    const [availableCriteria, setAvailableCriteria] = useState({})

    
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleRowClick = (params) => {  
        // `params` contiene toda la información de la fila clicada.  
        const rowId = params.row.id; 
        navigate(`.?img=${rowId}`);
    };
    
    const getResultIcon = (item) => {
        if (item) return "✔️";
        else if (item === false) return "❌";
        else return "➖";
    };

    const getSelectedRows = (selection, analyzed, allRows) => {
        setSelectedRows(selection)
        setAnalyzedRows(analyzed)
        setAllRows(allRows)
    }
    

    const getModuleList = async (data) =>{
        const new_rows = await Promise.all(data.map(async (item, index) => {
            const fileName = item.file_path.split('/').pop();
            const image = await fetchModuleThumbnail(projectName, item.module_id);
            return{
                module_id: item.module_id,
                id: index + 1,
                name: fileName,
                status: item.status ? "Detected" : "Not detected",
                accepted: getResultIcon(item.accepted),
                image: image.url,
                
            };
        }));
        setRows(new_rows)
    }

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                
                const id = await fetchProject(projectName);
                setProjectId(id)
                
                const data = await fetchAllModules(id);
                await getModuleList(data);
                // setProjectData(data);
                setIsAppReady(true)
                
            } catch (error) {
                console.error('Error fetching projects:', error);
                navigate('/404');
            }
        };

        const getCriteria = async () => {
            const criteriaList = await fetchCriteria()
            const ids = criteriaList.map(item => item[0])
            const names = criteriaList.map(item => item[1])
            const criteria = {'ids': ids, 'names': names }
            setAvailableCriteria(criteria)
        };
        
        fetchProjectData();
        getCriteria()

    }, []);

    useEffect(() => {
        if (error === 404) {
            navigate('/'); // Redirigir a la página 404
        }
    }, [error]);

    useEffect(() => {
        setSelectedRows([])
    }, [tabIndex]);

    if (!project) {
        return null;
    }

    const handleOpen = (window) => {
        switch (window) {
            case 'upload':
                setOpenImport(true);
                break;
            case 'delete':
                setOpenDelete(true)
                break;
            case 'inference':
                setOpenInference(true)
            default:
                break;
        }
    }
    
    const handleCloseReload = () => {
        setOpenDelete(false)
        setOpenImport(false)
        window.location.reload()
    };

    const handleClose = () => {
        setOpenImport(false)
        setOpenDelete(false)
        setOpenInference(false)
    }

    const startAnalysis = async (moduleIds) => {
        try {
            handleClose()
            await analyzeSetModules(projectId, moduleIds);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    }

    const deleteImages = async (selectedRows) => {
        await deleteSetModules(projectId, selectedRows)
        handleCloseReload()
    }

    return (
        <>
            {isAppReady ?
                <div>
                    <BreadcrumbHeader projectName={projectName} uploadFiles={() => handleOpen('upload')} startAnalysis={() => setOpenInference(true)} deleteFiles={() => handleOpen('delete')} selectedRows={selectedRows}/>
                    
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <ProjectTabs tabIndex={tabIndex} handleTabChange={handleTabChange} />
                            <Box p={2}>
                                {tabIndex === 0 && <ProjectTable rows={rows} handleRowClick={handleRowClick} selectRow={getSelectedRows}/>}
                                {tabIndex === 1 && <ProjectOverview projectId={projectId}/>}
                                {tabIndex === 2 && <ProjectSettings project={project} availableCriteria={availableCriteria}/>}
                            </Box>
                        </Box>
                    <ImportModal open={openImport} handleClose={handleCloseReload} projectId={projectId}/>
                    <DeleteModal open={openDelete} handleClose={handleClose} deleteData={selectedRows} deleteMethod={deleteImages} />
                    <InferenceModal open={openInference} handleClose={handleClose} selectedRows={selectedRows} analyzedRows={analyzedRows} analysisMethod={startAnalysis} allRows={allRows} />
                </div>
            :
                <LoadingScreen />
            }
        </>
    );
};

export default Project;
