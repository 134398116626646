// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React, { useEffect, useState } from 'react';
// Material-UI Components
import { Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';


const ProjectTable = ({rows, handleRowClick, selectRow}) => {
    const [analyzedModules, setAnalyzedModules] = useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'name',
            headerName: 'File Name',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.3,
        },
        {
            field: 'accepted',
            headerName: 'Accepted',
            flex: 0.2
        },
        {
            field: 'image',
            headerName: 'Image',
            width: 125,
            renderCell: (params) => (
                <img 
                    src={params.row.image} 
                    alt={params.row.fullName} 
                    style={{
                        width: '100px',  
                        marginTop:'10px',
                        objectFit: 'contain',  // Mantiene la relación de aspecto
                        borderRadius: '4px',   // Opcional: añade bordes redondeados si lo deseas
                        justifyContent: 'center'
                    }} 
                />
            ),
            sortable: false,
        }
    ];

    useEffect(() => {
        const analyzedList = []
        const allRows = rows.map(rows => rows.module_id)
        rows.forEach((row) => {
            if (row.status === 'Detected') {
                analyzedList.push(row.module_id); // Agrega module_id de filas detectadas
            }
        });

        setAnalyzedModules(analyzedList)
        selectRow([], analyzedList, allRows)
    }, []);

    const handleSelectionChangeInternal = (newSelection) => {
        // Almacenamos las filas seleccionadas
        const selectedModules = []
        if (selectRow) {
            newSelection.map(id => {
                const row = rows.find(row => row.id === id); // Busca la fila correspondiente al ID
                if (row){
                    selectedModules.push(row.module_id)
                }
            });

            selectRow(selectedModules, analyzedModules); // Llama a la función pasada desde el componente principal
        }
    };

    return(
        <Paper sx={{ height: '75vh' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={handleRowClick}
                rowHeight={70}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChangeInternal}
            />  
        </Paper>
    );
};

export default ProjectTable;
