// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React from 'react';

// Material-UI Components
import { Typography } from '@mui/material';


const PaymentMethod = () => {
    return(
        <>
            <div className='title'>
                <Typography variant='h3' color={'#656cf6'}>Payment Methods</Typography>
            </div>
        </>
    );
};

export default PaymentMethod;
