// Copyright (C) CELSOS. All rights reserved.
// Core Components
import React from 'react';

// Material-UI Components
import { Avatar, Button, IconButton, TextField, Typography } from '@mui/material';

const AccountInfo = () => {
    return(
        <>
            <div className='title'>
                <Typography variant='h3' color={'#656cf6'}>Account Info</Typography>
            </div>
            
            <div className='profile-pic'>

                <IconButton
                size="medium"
                sx={{ mb: 2 }}
                >
                    <Avatar sx={{ background: "#656cf6", width: 150, height: 150 }}>IC</Avatar>
                </IconButton>
                <Button variant='outlined' color='error'>Delete</Button>
            </div>

            <div className='personal-data'>
                <Typography>First Name</Typography>
                <TextField type="text" id="first-name" value="Iñaki" />
                
                <Typography>Last Name</Typography>
                <TextField type="text" id="last-name" value="Claramunt Oregi" />
                <Typography>Phone</Typography>
                <TextField type="text" id="phone" placeholder="Enter phone number" />
            </div>

            <div className='account-data'>
                <Typography>E-mail</Typography>
                <TextField type="email" id="email" value="iclaramunt@celsospv.com" disabled />
                <Typography>Company Name</Typography>
                <TextField id="cname" value="CelsosPV" disabled />
                
            
            </div>
            <div className='stuff'></div>
            <div className='save-changes'>
                <Button variant='contained'>Save</Button>
            </div>
                
            <div className='footer2'>
                <Typography>Registered Mar 8, 2024, user ID 5</Typography> 
            </div>
        </>
    );
};

export default AccountInfo;
