// Copyright (C) CELSOS. All rights reserved.
import React from 'react';

// Material-UI components
import { Box, Button, Modal, Typography } from '@mui/material';

// Styles
import '../../assets/styles/components/dragNDrop.css';

const DeleteModal = ({ open, handleClose, deleteData, deleteMethod }) => {
    
    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                handleClose();
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box className="small-modal-box">
                <Typography color="primary" variant="h5" sx={{ textAlign: 'left' }}>
                    Delete selected data?
                </Typography>
                <Typography color="error" variant="h7" sx={{ textAlign: 'left' }}>
                    {deleteData.length} images will be permanently deleted. Continue?
                </Typography>
                <div className="center">
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: '20px' }}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="error" onClick={() => deleteMethod(deleteData)} sx={{ marginTop: '20px', ml: '20px' }}>
                        Delete
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default DeleteModal;
