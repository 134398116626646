// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { createContext, useContext, useState } from 'react';

//Components
import UserService from '../services/UserService';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [error, setError] = useState(null);

    const fetchPinnedProjects = async () =>{
        try{
            const response = await UserService.getPinned();
            return response;
            } catch (err) {
            if (err.response && err.response.status == 404){
                throw err;
            } else {
                setError("Error fetching user's pinned projects")
            }
        }
    }

    const savePinnedProjects = async (pinnedList) =>{
        try{
            const response = await UserService.setPinned(pinnedList);
            return response;
            } catch (err) {
            if (err.response && err.response.status == 404){
                throw err;
            } else {
                setError("Error fetching user's pinned projects")
            }
        }
    }

    return (
        <UserContext.Provider value={{ fetchPinnedProjects, savePinnedProjects, error }}>
            {children}
        </UserContext.Provider>
    );
};