// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Copyright (C) CELSOS. All rights reserved. */
.title {
    grid-area: title; /* Abarca ambas columnas */
    font-weight: bold;
  }
  
  .profile-pic {
    grid-area: profile-pic;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .personal-data {
    flex-direction: column;
    grid-area: personal-data;
    display: flex;
    margin-bottom: -20px;
  }
  
  .account-data {
    grid-area: account-data;
    display: flex;
    flex-direction: column;
  }
  .save-changes {
    grid-area: footer; /* Abarca ambas columnas */
  }
  .footer2 {
    grid-area: footer2; /* Abarca ambas columnas */
    justify-content: flex-end;
    display: flex;
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/pages/ProfilePage/accountInfo.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;IACI,gBAAgB,EAAE,0BAA0B;IAC5C,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;IACtB,wBAAwB;IACxB,aAAa;IACb,oBAAoB;EACtB;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,iBAAiB,EAAE,0BAA0B;EAC/C;EACA;IACE,kBAAkB,EAAE,0BAA0B;IAC9C,yBAAyB;IACzB,aAAa;EACf","sourcesContent":["/* Copyright (C) CELSOS. All rights reserved. */\n.title {\n    grid-area: title; /* Abarca ambas columnas */\n    font-weight: bold;\n  }\n  \n  .profile-pic {\n    grid-area: profile-pic;\n    border-radius: 8px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .personal-data {\n    flex-direction: column;\n    grid-area: personal-data;\n    display: flex;\n    margin-bottom: -20px;\n  }\n  \n  .account-data {\n    grid-area: account-data;\n    display: flex;\n    flex-direction: column;\n  }\n  .save-changes {\n    grid-area: footer; /* Abarca ambas columnas */\n  }\n  .footer2 {\n    grid-area: footer2; /* Abarca ambas columnas */\n    justify-content: flex-end;\n    display: flex;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
