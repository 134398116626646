// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React from 'react';

// Material-UI Components
import { Typography } from '@mui/material';

// Components
import RoundedTypography from '../../UI/RoundedTypography';
import CheckboxSelect from '../../Feature/CheckboxSelect';

const ImageSidebarRight = ({ defectData, onToggleDefectVisibility, visibleDefects, setVisibleDefects, setHoveredDefectId, onDefectStyles, uniqueDefects, lens }) => {

    const disabledDefects = (selection) =>{

        // Disable all defects with the selected names
        defectData.forEach(df => {
            setVisibleDefects(prevState => ({
                ...prevState,
                [df.id]: selection.includes(df.name) ? false : true
            }));
        });
    }

    return (
        <div>
            <div className="top-section header" style={{ display:'flex', flexDirection:'row'}}>
                <Typography color='white'>FOUND DEFECTS</Typography>
                <CheckboxSelect defects={uniqueDefects} disabledDefects={disabledDefects}/>
            </div>
            <div className="top-section content">
                <div style={{ margin: '10px' }}>
                {defectData.map((defect) => {
                    const { icon, label, lightColor, darkColor, color } = onDefectStyles(defect.name, lens);
                    const isDefectVisible = visibleDefects[defect.id];
                    return (
                    <div
                        key={defect.id}
                        style={{ 
                        marginBottom: '10px', 
                        cursor: 'pointer',
                        }}
                        onClick={() => {onToggleDefectVisibility(defect.id);
                        }}
                        id={defect.id}
                        onMouseEnter={() => setHoveredDefectId(defect.id)}
                        onMouseLeave={() => setHoveredDefectId(null)}
                    >
                        <RoundedTypography 
                            borderRadius={20}
                            backgroundColor={isDefectVisible ? lightColor : '#B0B0B0'}
                            color={isDefectVisible ? darkColor : '#7D7D7D'}
                            icon={icon ? icon : undefined}
                            >
                            {`${label} - ${(defect.score * 100).toFixed(2)}%`}
                        </RoundedTypography>
                    </div>
                    );
                })}
                </div>
            </div>
        
        </div>
    )
};

export default ImageSidebarRight;
