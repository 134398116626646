// Copyright (C) CELSOS. All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import App from './App';

// Contexts
import { BrowserRouter as Router} from "react-router-dom";
import { ProjectProvider } from './contexts/ProjectContext';
import { CriteriaProvider } from './contexts/CriteriaContext';
import { ModuleProvider } from './contexts/ModuleContext';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingProvider } from './contexts/LoadingContext';

// Styles
import './assets/styles/global.css';
import './assets/fonts/fonts.css';
import './index.css';
import { UserProvider } from './contexts/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <AuthProvider>
            <LoadingProvider>
                <UserProvider>
                    <ProjectProvider>
                        <CriteriaProvider>
                            <ModuleProvider>
                                <App />
                            </ModuleProvider>
                        </CriteriaProvider>
                    </ProjectProvider>
                </UserProvider>
            </LoadingProvider>
        </AuthProvider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
