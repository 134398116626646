// Copyright (C) CELSOS. All rights reserved.
// Core Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material-UI Components
import { Button, Box, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import RandomTextEffect from '../components/UI/TextFadeIn';

// Custom Components
// import ProjectComponent from '../components/Feature/ProjectComponent';

// Contexts
import { useCriteria } from '../contexts/CriteriaContext';

// Styles
import '../assets/styles/pages/home.css';
import Footer from '../components/Layout/Global/Footer';


const Criteria = () => {
  const navigate = useNavigate();
  const [criteria, setCriteria] = useState([]);
  const { fetchCriteria } = useCriteria();

  // const { currentAccessToken, setCurrentAccessToken } = useAuth();
  // useSession(currentAccessToken, setCurrentAccessToken);

    useEffect(() => {
        const fetchData = async () => {
            try{
                const crit = await fetchCriteria()
                setCriteria(crit)
            }
            catch (error){
            console.error('Error fetching projects:', error);
            }
        }
        fetchData();
        
    }, []);
    return (
        <>
          <div className="page-container">
            <div className="content text-center">
                <Box height={180}></Box>
                <Box  justifyContent="flex-end" display="flex" flexDirection="column" alignItems="center" backgroundColor="#1a1949BB" borderRadius={2} p={2} mt={2}>
                    <Typography variant="h3" gutterBottom>
                        Custom criteria creation coming soon
                    </Typography>
                        <RandomTextEffect fntSize = "180%" skipFurther={true} text="Create your own criteria according to the specifications in your contract" >
                            
                        </RandomTextEffect>
                        <RandomTextEffect fntSize = "180%" skipFurther={true} text='And reject the ones that do not meet your requirements'>
                            
                        </RandomTextEffect>
                </Box>
                
            </div>
            <Footer />
          </div>
        </>
      );

//   return (
//     <>
//       <div className='grid-container'>
//         <div className='grid-item'>
//           <Button
//             sx={{
//                 borderRadius:2,
//                 backgroundColor: '#1a1949', 
//                 color: '#656aff', 
//                 height: '100%',
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center', // Centra verticalmente el contenido
//                 justifyContent: 'center', // Centra horizontalmente el contenido
//             }}
//             onClick={()=>{
//               navigate('/newcriteria')
//             }}>
//             <Box>
//               <AddBoxIcon
//                 sx={{ marginLeft:'5%', fontSize: 70, color: 'main' }} // Tamaño del ícono
//               />
//               <Typography sx={{ fontWeight:'bold' }}>Create Criteria</Typography></Box>
            
//           </Button>
//         </div>
//         {/* {criteria.map((project) => (
//           <div className='grid-item' key={project.id}>
//             <ProjectComponent 
//               title={project.name}
//               completedTasks={822}
//               description={project.desc}
//               date={project.date}
//               onClick={() => navigate(`/Criteria/${project.id}`)}
//             />
//           </div>
//         ))} */}
//       </div>
//       <Box mt='22%' ></Box>
//       <Footer />
//     </>
//   );
}

export default Criteria;
