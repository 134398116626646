import { Circle, CircleTwoTone, TripOriginOutlined } from "@mui/icons-material";

// Copyright (C) CELSOS. All rights reserved.
export const getDefectStyles = (name, lens) => {

    if (lens === 'severity') {

        switch (name) {
            case "Dot":
                return { icon: <Circle sx={{ color: '#00d26a' }}/>, label: 'Dot', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            case "TypeACrack":
                return { icon: <Circle sx={{ color: '#f6bf36' }}/>, label: 'Type A Crack', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "FalseSoldering":
                return { icon: <Circle sx={{ color: '#0074ba' }}/>, label: 'False Soldering', lightColor: '#ede99d', darkColor: '#95680c', color: '#ffc64d' };
            case "CrossCrack":
                return { icon: <Circle sx={{ color: '#8d65c5' }}/>, label: 'Cross Crack', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "MassiveCracks":
                return { icon: <Circle sx={{ color: '#6d4534' }}/>, label: 'Massive Cracks', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "TypeDCrack":
                return { icon: <Circle sx={{ color: '#f8312f' }}/>, label: 'Type D Crack', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "TypeBCCrack":
                return { icon: <Circle sx={{ color: '#ff8d30' }}/>, label: 'Type BC Crack', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "BypassDiode":
                return { icon: <Circle sx={{ color: '#000000' }}/>, label: 'Bypass Diode', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "ManufacturingPrints":
                return { icon: <Circle sx={{ color: '#ffffff' }}/>, label: 'Manufacturing Prints', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            case "EdgeWafer":
                return { icon: <TripOriginOutlined sx={{ color: '#321b41' }}/>, label: 'Edge Wafer', lightColor: '#ede99d', darkColor: '#95680c', color: '#ffc64d' };
            case "Finger":
                return { icon: <TripOriginOutlined sx={{ color: '#f92f60' }}/>, label: 'Finger', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            case "GridLineShadow":
                return { icon: <Circle sx={{ color: '#216757' }}/>, label: 'Gridline Shadow', lightColor: '#ede99d', darkColor: '#95680c', color: '#ffc64d' };
            default:
                return { label: 'O Others', lightColor: '#ffffff', darkColor: '#08401e', color: '#000000' };
        }
    }else if (lens === 'rejected'){
        switch (name) {
            case "Dot":
                return { icon: <Circle sx={{ color: '#00d26a' }}/>, label: 'Dot', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            case "TypeACrack":
                return { icon: <Circle sx={{ color: '#f6bf36' }}/>, label: 'Type A Crack', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "FalseSoldering":
                return { icon: <Circle sx={{ color: '#0074ba' }}/>, label: 'False Soldering', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            case "CrossCrack":
                return { icon: <Circle sx={{ color: '#8d65c5' }}/>, label: 'Cross Crack', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "MassiveCracks":
                return { icon: <Circle sx={{ color: '#6d4534' }}/>, label: 'Massive Cracks', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "TypeDCrack":
                return { icon: <Circle sx={{ color: '#f8312f' }}/>, label: 'Type D Crack', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "TypeBCCrack":
                return { icon: <Circle sx={{ color: '#ff8d30' }}/>, label: 'Type BC Crack', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "BypassDiode":
                return { icon: <Circle sx={{ color: '#000000' }}/>, label: 'Bypass Diode', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "ManufacturingPrints":
                return { icon: <Circle sx={{ color: '#ffffff' }}/>, label: 'Manufacturing Prints', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            case "EdgeWafer":
                return { icon: <TripOriginOutlined sx={{ color: '#321b41' }}/>, label: 'Edge Wafer', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            case "Finger":
                return { icon: <TripOriginOutlined sx={{ color: '#f92f60' }}/>, label: 'Finger', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            case "GridLineShadow":
                return { icon: <Circle sx={{ color: '#216757' }}/>, label: 'Gridline Shadow', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            default:
                return { label: 'O Others', lightColor: '#ffffff', darkColor: '#08401e', color: '#000000' };
                
        }
    }else{
        switch (name) {
            case "Dot":
                return { icon: <Circle sx={{ color: '#00d26a' }}/>, label: 'Dot', lightColor: '#99e096', darkColor: '#08401e', color: '#5ffca2' };
            case "TypeACrack":
                return { icon: <Circle sx={{ color: '#f6bf36' }}/>, label: 'Type A Crack', lightColor: '#ede99d', darkColor: '#95680c', color: '#ffc64d' };
            case "FalseSoldering":
                return { icon: <Circle sx={{ color: '#0074ba' }}/>, label: 'False Soldering', lightColor: '#6b8efd', darkColor: '#1f076e', color: '#3e4dfa' };
            case "CrossCrack":
                return { icon: <Circle sx={{ color: '#8d65c5' }}/>, label: 'Cross Crack', lightColor: '#f995fc', darkColor: '#310982', color: '#b784ff' };
            case "MassiveCracks":
                return { icon: <Circle sx={{ color: '#6d4534' }}/>, label: 'Massive Cracks', lightColor: '#ad8671', darkColor: '#2b1a0f', color: '#b6785b' };
            case "TypeDCrack":
                return { icon: <Circle sx={{ color: '#f8312f' }}/>, label: 'Type D Crack', lightColor: '#f57693', darkColor: '#780520', color: '#ed2b54' };
            case "TypeBCCrack":
                return { icon: <Circle sx={{ color: '#ff8d30' }}/>, label: 'Type BC Crack', lightColor: '#ffc182', darkColor: '#662c02', color: '#e37922' };
            case "BypassDiode":
                return { icon: <Circle sx={{ color: '#000000' }}/>, label: 'Bypass Diode', lightColor: '#000000', darkColor: '#9c9c9c', color: '#dbdbdb' };
            case "ManufacturingPrints":
                return { icon: <Circle sx={{ color: '#ffffff' }}/>, label: 'Manufacturing Prints', lightColor: '#dedede', darkColor: '#6e6e6e', color: '#9c9c9c' };
            case "EdgeWafer":
                return { icon: <TripOriginOutlined sx={{ color: '#321b41' }}/>, label: 'Edge Wafer', lightColor: '#c9c9c9', darkColor: '#000000', color: '#737373' };
            case "Finger":
                return { icon: <TripOriginOutlined sx={{ color: '#f92f60' }}/>, label: 'Finger', lightColor: '#dedede', darkColor: '#ef3f63', color: '#a61734' };
            case "GridLineShadow":
                return { icon: <Circle sx={{ color: '#216757' }}/>, label: 'Gridline Shadow', lightColor: '#86e3d1', darkColor: '#0a4237', color: '#4fc2ab' };
            default:
                return { label: 'O Others', lightColor: '#ffffff', darkColor: '#08401e', color: '#000000' };
        }
    }
    
};